import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import '../../../../../services/slide-up-widget';
import Action from '../../Action';
import TextInput from '../../TextInput';
import useDebounce from '../../../../../hooks/use-debounce';

import * as styles from './SbpForm.module.scss';
import FormsRow from '../../FormsRow';

const SbpForm = ({
  sbpQrLink,
  onDesktop
}) => {
  const [isInit, setIsInit] = useState(true);
  const [banks, setBanks] = useState([]);
  const [findbanks, setFindBanks] = useState([]);
  const [searchName, setSearchName] = useState('');
  const deferredSearchName = useDebounce(searchName, 500);
  // eslint-disable-next-line no-undef
  const slideUpWidget = new SlideUpWidget(window.localStorage, window.navigator);

  const hanleSearchBankName = (e) => {
    e.preventDefault();
    console.log(e.currentTarget.value);
    setSearchName(e.currentTarget.value);
  };

  useEffect(() => {
    if (isInit) {
      slideUpWidget.getBankList(sbpQrLink)
        .then((response) => {
          setBanks(response);
          setFindBanks(response);
          console.log(response);
          setIsInit(false);
        });
    }
    const search = () => {
      console.log('deferredSearchName =>', deferredSearchName);
      if (deferredSearchName.length === 0) {
        setFindBanks(banks);
        return;
      }

      if (deferredSearchName.length > 0) {
        const data = banks.filter(bank => bank.bankName.toLowerCase().includes(deferredSearchName.toLowerCase()));
        setFindBanks(data.length > 0 ? data : []);
      }
    };

    search();
  }, [deferredSearchName]);

  return (
    <div className={styles.SbpForm}>
      <div className={styles.SbpForm__search}>
        <FormsRow>
          <TextInput
            placeholder='Введите название банка'
            type='text'
            onChange={hanleSearchBankName}
          />
        </FormsRow>
        <FormsRow>
          <Action
            themes={['Link']}
            onClick={() => onDesktop()}
          >
            Оплатить по QR-код
          </Action>
        </FormsRow>
      </div>
      <div className={styles.SbpForm__banks}>
        {findbanks.map(bank => (
          <Action
            themes={['Link']}
            href={bank.dboLink}
          >
            <div className={styles.SbpForm__bank}>
              <img alt={bank.bankName} src={bank.logoURL} />
              <div>
                {bank.bankName}
              </div>
            </div>
          </Action>
        ))}
      </div>
    </div>
  );
};

SbpForm.propTypes = {
  sbpQrLink: PropTypes.string,
  onDesktop: PropTypes.func.isRequired
};

SbpForm.defaultProps = {
  sbpQrLink: process.env.T_BANK_SBP_LINK
};

export default SbpForm;
