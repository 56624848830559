import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import VkLogoIcon from '../../../../../../icons/vk_id1.svg';
import SnowflakeIcon from '../../../../../../icons/SW.svg';

import * as styles from './VkButton.module.scss';
import IconSvg from '../../../../../common/javascript/components/IconSvg/IconSvg';
import Action from '../../Action';

const VkButton = ({ className, onClick }) => (
  <div
    className={
      cn(
        styles.VkButton,
        { [className]: className }
      )
    }
  >
    <Action
      className={cn(styles.VkButton, styles.VkButton__button, styles.VkButton__button_reset)}
      onClick={() => onClick()}
      themes={['Button', 'Fullwidth']}
    >
      <IconSvg icon={VkLogoIcon} className={styles.VkButton__logo} />
      <div className={styles.VkButton__info}>Войти c VK ID</div>
      <IconSvg icon={SnowflakeIcon} className={styles.VkButton__avatar} />
    </Action>
  </div>
);

VkButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

VkButton.defaultProps = {
  className: ''
};

export default VkButton;
