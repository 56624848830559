export const amountWithInternetAcquiring = (amount, acquiring = 0) => {
  if (acquiring === 0) return amount;

  const pay = Math.floor(parseFloat(amount) / 100 * acquiring);

  return parseFloat(pay) + parseFloat(amount);
};

export const internetAcquiringAmount = (amount, acquiring = 0) => {
  if (acquiring === 0) return 0;

  const pay = Math.floor(parseFloat(amount) / 100 * acquiring);

  return parseFloat(pay);
};

export const productAmountWithDiscountSbp = (products) => {
  return products.map(p => p.products).reduce((acc, p) => { return acc + p[0].discount_sbp_for_all_pay }, 0);
};

export const discountAmount = (amount, discount = 0) => {
  if (discount === 0) return 0;

  const pay = Math.floor(parseFloat(amount) / 100 * discount);

  return parseFloat(pay);
};

export const amountWithDiscount = (amount, discount = 0) => {
  if (discount === 0) return amount;

  const pay = Math.floor(parseFloat(amount) / 100 * discount);

  return parseFloat(amount) - parseFloat(pay);
};
