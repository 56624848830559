import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Popup from '../Popup';
import usePopup from '../../../../hooks/use-popup';
import { OrderContext } from '../../context/OrderContext';
import { ORDER_ITEMS } from '../MenuItems';
import Hint from '../Hint';
import { viewedHint } from '../../../../services/order';

const HintPreview = ({ tab }) => {
  const { order, handleMenuItemChange } = useContext(OrderContext);
  const [isHintOpened, openHint, closeHint] = usePopup();
  const [content, setContent] = useState(null);

  const popupHintContent = (item) => {
    if (order.hints?.orders_hint && item === ORDER_ITEMS.orderItems) return order.hints?.orders_hint;
    if (order.hints?.sub_orders_hint && item === ORDER_ITEMS.subOrders) return order.hints?.sub_orders_hint;
    if (order.hints?.documents_hint && item === ORDER_ITEMS.documents) return order.hints?.documents_hint;
    if (order.hints?.members_hint && item === ORDER_ITEMS.members) return order.hints?.members_hint;

    return null;
  };

  const changeAction = (item, viewedHints) => {
    if (viewedHints.includes(item)) return;

    const hint = popupHintContent(item);

    setContent(hint);

    if (!hint) return;

    openHint();
  };

  const handleCloseHint = () => {
    closeHint();
    viewedHint(order.id, tab, (result) => {
      // console.log(result);
    });
  };

  useEffect(() => {
    if (!order.hints) return;

    handleMenuItemChange(tab, changeAction);
  }, [order.hints]);

  return (
    <Popup
      show={isHintOpened}
      onClose={handleCloseHint}
      variant='small'
    >
      <Hint content={content} onClose={handleCloseHint} />
    </Popup>
  );
};

HintPreview.propTypes = {
  tab: PropTypes.string.isRequired
};

HintPreview.defaultProps = {
};

export default HintPreview;
