import React, { useContext, useEffect } from 'react';
// import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { OrderContext } from '../../../context/OrderContext';
import Select from '../../Select';
import FormsRow from '../../FormsRow';
import useDebounce from '../../../../../hooks/use-debounce';

import * as styles from './BedType.module.scss';

const BedType = () => {
  const { order, requestBedType, handleUpdateOrder } = useContext(OrderContext);
  const submitRef = React.createRef();

  const {
    register,
    watch,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChanges',
    reValidateMode: 'onChange',
    defaultValues: {
      bedType: order.request_bed_type || 'null'
    }
  });

  const bedType = watch('bedType');

  const deferredBedType = useDebounce(bedType, 1000);

  const options = (
    Object.entries(requestBedType).map(([k, v]) => (
      <option key={`option-${k}`} value={k}>{v}</option>
    ))
  );

  useEffect(() => {
    if ((order.request_bed_type || 'null') === bedType) return;

    submitRef.current.click();
  }, [deferredBedType]);

  const handleBedType = (e) => {
    const { value } = e.currentTarget;

    setValue('bedType', value);
  };

  const onSubmit = (formData) => {
    console.log('onSubmit => ', formData);
    // alert(`Sending: ${JSON.stringify(formData)}`);
    // return;

    const params = {
      order: {
        request_bed_type: formData.bedType
      }
    };

    handleUpdateOrder(params, (orderResult) => {
      console.log(orderResult);
    });
  };

  return (
    <form
      className={styles.BedType}
      onSubmit={handleSubmit(data => onSubmit(data))}
    >
      <div>
        <FormsRow>
          <Select
            className={styles.BedType__select}
            name='request_bed_type'
            {...register('bedType')}
            error={errors.bedType?.message}
            value={bedType}
            onChange={handleBedType}
          >
            {options}
          </Select>
        </FormsRow>

        <input type='submit' ref={submitRef} style={{ display: 'none' }} />
      </div>
    </form>
  );
};

BedType.propTypes = {

};

export default BedType;
