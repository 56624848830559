import React from 'react';
import PropTypes from 'prop-types';

const IconSvg = ({ icon, className }) => (
  <svg className={className} viewBox={icon.viewBox}>
    <use xlinkHref={icon.url} />
  </svg>
);

IconSvg.propTypes = {
  icon: PropTypes.shape({
    viewBox: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  className: PropTypes.string
};

IconSvg.defaultProps = {
  className: ''
};

export default IconSvg;
