import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../TextInput';
import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';

import CalendarIcon from '../../../../../icons/calendar-new.svg';

import * as styles from './TimeTextInput.module.scss';

const TimeTextInput = forwardRef((props, ref) => {
  const {
    placeholder,
    type,
    ...otherInputProps
  } = props;

  const mask = {
    time: true,
    delimiter: ':',
    timePattern: ['h', 'm']
  };

  return (
    <TextInput
      placeholder={placeholder}
      type={type}
      mask={mask}
      {...otherInputProps}
      afterContent={
        <div className={styles.TimeTextInput__icon}><IconSvg icon={CalendarIcon} /></div>
      }
      ref={ref}
    />
  );
});

TimeTextInput.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string
};

TimeTextInput.defaultProps = {
  placeholder: 'ЧЧ:ММ',
  type: 'text'
};

export default TimeTextInput;
