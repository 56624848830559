import React from 'react';
import PropTypes from 'prop-types';

import OfferCard from '../OfferCard';

import * as styles from './OffersList.module.scss';

const OffersList = ({ offers }) => {
  return (
    <div className={styles.OffersList}>
      {offers.map(offer => (
        <OfferCard key={`offer-card__${offer.id}`} offer={offer} />
      ))}
    </div>
  );
};

OffersList.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })).isRequired
};

export default OffersList;
