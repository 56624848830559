import React from 'react';
import PropTypes from 'prop-types';

import OrderCard from '../OrderCard';

import * as styles from './OrdersList.module.scss';

const OrdersList = ({ orders, referal }) => (
  <div className={styles.OrdersList}>
    {orders.map(order => (
      <OrderCard key={`order-card__${order.id}`} order={order} referal={referal} />
    ))}
  </div>
);

OrdersList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })).isRequired,
  referal: PropTypes.bool
};

OrdersList.defaultProps = {
  referal: false
};

export default OrdersList;
