/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../TextInput';

import * as styles from './PhoneTextInput.module.scss';

const PhoneTextInput = forwardRef((props, ref) => {
  const {
    placeholder,
    type,
    searchPhone,
    ...otherInputProps
  } = props;

  const mask = {
    blocks: [2, 3, 3, 4],
    delimiters: ['(', ')', '-'],
    numericOnly: true,
    prefix: '+7'
  };

  return (
    <div className={styles.PhoneTextInput}>
      <TextInput
        placeholder={placeholder}
        type={type}
        autoComplete='one-time-code'
        inputMode='numeric'
        phone
        {...otherInputProps}
        mask={mask}
        ref={ref}
      />
    </div>
  );
});

PhoneTextInput.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  searchPhone: PropTypes.bool
};

PhoneTextInput.defaultProps = {
  placeholder: '+7(985)761-0289',
  type: 'text',
  searchPhone: false
};

export default PhoneTextInput;
