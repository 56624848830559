import * as React from 'react';
import PropTypes from 'prop-types';
import { Routes } from '../../../../helpers/routes';

import Action from '../Action';
import { Tabs, Tab } from '../Tabs';
import * as styles from './ProfileTabs.module.scss';

export const PROFILE_TABS = {
  orders: 'ordres',
  account: 'account'
};

const ProfileTabs = ({ currentTab }) => {
  return (
    <div className={styles.ProfileTabs}>
      <Tabs>
        <Tab isActive={currentTab === PROFILE_TABS.orders}>
          <Action
            href={Routes.orders}
          >
            Мои заказы
          </Action>
        </Tab>

        <Tab isActive={currentTab === PROFILE_TABS.account}>
          <Action
            href={Routes.account}
          >
            Профиль
          </Action>
        </Tab>
      </Tabs>
    </div>
  );
};

ProfileTabs.propTypes = {
  currentTab: PropTypes.string.isRequired
};

export default ProfileTabs;
