import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import FormsRow from '../../FormsRow';
import TextInput from '../../TextInput';
import DateTextInput from '../../DateTextInput';
import PhoneTextInput from '../../PhoneTextInput/PhoneTextInput';
import Select from '../../Select';

import { defaultDateFormat } from '../../../../../helpers/dates';

import * as styles from './MemberForm.module.scss';
import { nullableDate, nullableString } from '../../../../../helpers/yupValidation';

const gender = (
  <>
    <option value='man'>Мужской</option>
    <option value='woman'>Женский</option>
  </>
);

const MIN_LENGTH = 2;
const MAX_LENGTH = 50;

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(MIN_LENGTH, `Должно быть больше ${MIN_LENGTH} символов`)
    .max(MAX_LENGTH, `Должно быть меньше ${MAX_LENGTH} символов`)
    .required('Заполни ИМЯ'),
  surname: Yup.string()
    .trim()
    .min(MIN_LENGTH, `Должно быть больше ${MIN_LENGTH} символов`)
    .max(MAX_LENGTH, `Должно быть меньше ${MAX_LENGTH} символов`)
    .required('Заполни ФАМИЛИЮ'),
  phone: Yup.string()
    .nullable()
    .transform(nullableString)
    // .required('Заполни НОМЕР ТЕЛЕФОНА'),
    .length(15, 'Неверный номер телефона'),
  email: Yup.string()
    .nullable()
    .email('Должен быть корректный email')
    .max(255),
  birthday: Yup.date()
    .nullable()
    .transform(nullableDate)
    .max(new Date(), 'Ты не можешь родиться в будущем')
    .typeError('Заполни ДАТУ РОЖДЕНИЯ')
    // .required('Заполни ДАТУ РОЖДЕНИЯ')
  // sex: Yup.string()
  //   .required('Заполни ПОЛ')
});

const passportSchema = Yup.object().shape({
  passport_number: Yup.string()
    .trim()
    .nullable()
    .transform(nullableString)
    .min(10, 'Должен быть 10 символов')
    .max(11, 'Должен быть 10 символов')
    // .required('Заполни СЕРИЯ И НОМЕР ПАСПОРТА'),
    .typeError('Введи корректный номер XXXX XXXXXX'),
  passport_expiration_date: Yup.date()
    // .transform(defaultDateFormat)
    .nullable()
    .transform(nullableDate)
    .max(new Date(), 'Твой паспорт ещё не выдан')
    .min(new Date(1945, 1, 1), 'Ты слишком старый')
    // .required('Заполни ДАТА ВЫДАЧИ')
    .typeError('Введи корректную дату ДД.ММ.ГГГГ')
});

const birthCertificateSchema = Yup.object().shape({
  passport_number: Yup.string()
    .trim()
    .nullable()
    .transform(nullableString)
    .min(11, 'Должен быть 11 символов')
    .max(12, 'Должен быть 11 символов')
    // .required('Заполни СВИДЕТЕЛЬСТВО О РОЖДЕНИИ'),
    .typeError('Введи корректный номер XX-XX XXXXXX'),
  passport_expiration_date: Yup.date()
    .nullable()
    .transform(nullableDate)
    // .transform(defaultDateFormat)
    .max(new Date(), 'Твоё свидетельство ещё не выдано')
    .min(new Date(1945, 1, 1), 'Ты слишком старый')
    // .required('Заполни ДАТА ВЫДАЧИ')
    .typeError('Введи корректную дату ДД.ММ.ГГГГ')
});

const MemberForm = ({ member, prepareData, onSubmit }) => {
  const isChild = (member.age || 14) < 14;
  const formSchema = FormSchema.concat(isChild ? birthCertificateSchema : passportSchema);
  const passportMask = {
    blocks: [4, 6],
    delimiters: [' '],
    numericOnly: true
  };
  const birthCertMask = {
    blocks: [2, 2, 6],
    delimiters: ['-', ' ']
  };
  const mask = isChild ? birthCertMask : passportMask;
  const {
    register,
    watch,
    setValue,
    setError,
    // control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChanges',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema)
  });

  const phone = watch('phone');
  const birthday = watch('birthday');
  const email = watch('email');
  const sex = watch('sex');
  const passport_number = watch('passport_number');
  const passport_expiration_date = watch('passport_expiration_date');

  const callbackErrors = (cerrors) => {
    console.log('MemberForm => ', cerrors);
    Object.entries(cerrors).forEach(([key, value]) => {
      setError(key, { message: value });
    });
  };

  useEffect(() => {
    setValue('phone', prepareData ? member.profile?.phone : member?.phone);
    setValue('birthday', prepareData ? member.profile?.birthday : member?.birthday);
    setValue('sex', prepareData ? member.profile?.sex : member?.sex);
    setValue('email', prepareData ? member.profile?.email : member?.email);
    setValue('surname', prepareData ? member.profile?.surname : member?.surname);
    setValue('name', prepareData ? member.profile?.name : member?.name);
    setValue('patronymic', prepareData ? member.profile?.patronymic : member?.patronymic);
    setValue('passport_number', prepareData ? member.profile?.number : member?.passport_number);
    setValue('passport_expiration_date', prepareData ? member.profile?.issuance_date : member?.passport_expiration_date);
  }, []);

  return (
    <form
      className={styles.MemberForm}
      onSubmit={handleSubmit(data => onSubmit(data, member.id, cerrors => callbackErrors(cerrors)))}
      id={member.id}
    >
      <FormsRow>
        <TextInput
          labelText='Фамилия*'
          type='text'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('surname')}
          error={errors.surname?.message}
        />
      </FormsRow>

      <FormsRow>
        <TextInput
          labelText='Имя*'
          type='text'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('name')}
          error={errors.name?.message}
        />
      </FormsRow>

      <FormsRow>
        <TextInput
          labelText='Отчество для авиабилетов'
          type='text'
          // eslint-disable-next-line react/patronymicjsx-props-no-spreading
          {...register('patronymic')}
          error={errors.patronymic?.message}
        />
      </FormsRow>

      <FormsRow>
        <PhoneTextInput
          labelText='Номер телефона'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('phone')}
          error={errors.phone?.message}
          disabled={member.firstMember}
          value={phone}
        />
      </FormsRow>

      <FormsRow>
        <TextInput
          labelText='Email'
          type='text'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('email')}
          error={errors.email?.message}
          value={email}
        />
      </FormsRow>

      <FormsRow>
        <DateTextInput
          labelText='Дата рождения'
          // eslint-disable-next-line react/jsx-props-no-spreading
          // {...register('birthday')}
          {...register('birthday')}
          error={errors.birthday?.message}
          value={birthday}
        />
      </FormsRow>

      <FormsRow>
        <Select
          labelText='Пол'
          name='user-sex'
          {...register('sex')}
          error={errors.sex?.message}
          value={sex}
        >
          {gender}
        </Select>
      </FormsRow>

      {!isChild && (
        <FormsRow>
          <TextInput
            mask={mask}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('passport_number')}
            error={errors.passport_number?.message}
            labelText='СЕРИЯ И НОМЕР ПАСПОРТА ДЛЯ АВИАБИЛЕТОВ'
            placeholder='4605 231964'
            value={passport_number}
          />
        </FormsRow>
      )}

      {isChild && (
        <FormsRow>
          <TextInput
            mask={mask}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('passport_number')}
            error={errors.passport_number?.message}
            labelText='СВИДЕТЕЛЬСТВО О РОЖДЕНИИ ДЛЯ АВИАБИЛЕТОВ'
            placeholder='IV-СБ 231964'
            value={passport_number}
          />
        </FormsRow>
      )}

      <FormsRow>
        <DateTextInput
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('passport_expiration_date')}
          error={errors.passport_expiration_date?.message}
          labelText='ДАТА ВЫДАЧИ ДЛЯ АВИАБИЛЕТОВ'
          placeholder='ДД.ММ.ГГГГ'
          value={passport_expiration_date}
        />
      </FormsRow>
    </form>
  );
};

MemberForm.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  prepareData: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

MemberForm.defaultProps = {
  prepareData: false
};

export default MemberForm;
