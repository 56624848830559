const headersWithToken = (contentType) => {
  const headers = new Headers();
  const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const contentTypeLocal = contentType || 'application/json';

  headers.append('X-CSRF-Token', csrf);
  headers.append('X-Requested-With', 'XMLHttpRequest');
  headers.append('WWW-Authenticate', 'BasicCustom');
  headers.append('Content-Type', contentTypeLocal);
  return headers;
};

export default headersWithToken;
