import React, { useRef, useState } from 'react';

import usePopup from '../../../../../hooks/use-popup';

import Action from '../../Action/Action';
import Popup from '../../Popup/Popup';
import PersonalForm from '../PersonalForm';
import PassportForm from '../PassportForm';
import ForeignPassportForm from '../ForeignPassportForm';
import PasswordForm from '../PasswordForm';
import useAuth from '../../../../../hooks/use-auth';

import { handleUpdateUser, handleUpdatePassword } from '../../../../../services/personal';

import * as styles from './Sections.module.scss';

const SECTIONS_TRANSLATIONS = {
  personal: 'Профиль',
  password: 'Пароль для входа',
  passport: 'Паспорт РФ',
  foreign_passport: 'Загранпаспорт'
};

const SECTIONS = {
  personal: PersonalForm,
  password: PasswordForm,
  passport: PassportForm,
  foreign_passport: ForeignPassportForm
};

const Sections = () => {
  const [isPopupOpened, openPopup, closePopup] = usePopup();
  const [isSubscription, openSubscription, closeSubscription] = usePopup();
  const currentForm = useRef('personal');
  const { refresh } = useAuth();
  const [subscriptionEmail, setSubscriptionEmail] = useState('');

  const handleSectionClick = (section) => {
    currentForm.current = section;
    openPopup();
  };

  const FormToRender = SECTIONS[currentForm.current];

  const handleFormSubmit = (values, setSubmitting, cerrors) => {
    // alert(JSON.stringify(values, null, 2));
    console.log(setSubmitting, values);
    if (setSubmitting === 'password') {
      handleUpdatePassword(values, (result) => {
        console.log(result);
        closePopup();
      });
    } else {
      handleUpdateUser(values, setSubmitting, (result) => {
        console.log(result);
        if (result.status === 'ok') {
          refresh(result.user);
          closePopup();

          if (result?.subscription?.ok) {
            setSubscriptionEmail(result?.subscription?.email);
            openSubscription();
          }
        }

        if (result.status === 'error') {
          cerrors(result.error);
        }
      });
    }
  };

  return (
    <div className={styles.Sections}>
      {
        Object.keys(SECTIONS).map(section => (
          <Action
            onClick={() => handleSectionClick(section)}
            className={styles.Sections__item}
            noTheme
            key={section}
          >
            {SECTIONS_TRANSLATIONS[section]}
            <span className={styles.Sections__modifyLink}>изменить</span>
          </Action>
        ))
      }

      <Popup
        show={isPopupOpened}
        onClose={closePopup}
        escapeClose={false}
        outsideClose={false}
        variant='medium'
        header={(<h3>{SECTIONS_TRANSLATIONS[currentForm.current]}</h3>)}
        footer={
          (
            <Action themes={['Button', 'Fullwidth', 'ButtonCommon', 'ButtonWarning']} type='submit' form={currentForm.current}>
              Сохранить
            </Action>
          )
        }
      >
        <FormToRender
          formId={currentForm.current}
          onSubmit={handleFormSubmit}
        />
      </Popup>

      <Popup
        show={isSubscription}
        onClose={closeSubscription}
        variant='small'
        header={(<h3>Проверьте свою почту</h3>)}
        footer={
          (
            <Action themes={['Button', 'Fullwidth', 'ButtonCommon', 'ButtonWarning']} onClick={closeSubscription}>
              OK, понятно
            </Action>
          )
        }
      >
        Иногда наши важные уведомления попадают в спам.
        Мы отправили на&nbsp;
        <strong>{subscriptionEmail}</strong>
        &nbsp;письмо с подтверждением.
      </Popup>
    </div>
  );
};

export default Sections;
