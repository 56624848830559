import * as React from 'react';

import cn from 'classnames';

import * as styles from './Action.module.scss';

const getRel = ({ rel, target }) => {
  if (target === '_blank') {
    return `${rel || ''} noopener noreferrer`;
  }

  return rel;
};

const getComponent = ({ to, href }) => {
  if (to) {
    return 'a';
  }

  if (href) {
    return 'a';
  }

  return 'button';
};

export default function Action({
  rel,
  type,
  themes = ['Link'],
  className,
  children,
  noTheme = false,
  ...props
}) {
  const Component = getComponent({ to: props.to, href: props.href });
  const actionRel = getRel({ rel, target: props.target });
  const actionType = Component === 'button' ? type : undefined;

  const themeClassName = themes
    .map(theme => styles[theme])
    .filter(Boolean)
    .join(' ');

  return (
    <Component
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      rel={actionRel}
      type={actionType}
      className={cn({ [themeClassName]: !noTheme }, className)}
    >
      {children}
    </Component>
  );
}
