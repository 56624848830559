export default function action(urlAction, params, method, retContentType = 'json') {
  const promise = new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open(method, urlAction, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          if (retContentType === 'json') {
            resolve(JSON.parse(xhr.responseText));
          }
          if (retContentType === 'text') {
            resolve(xhr.responseText);
          }
        } else {
          reject(xhr.status);
        }
      }
    };
  });

  return promise;
}
