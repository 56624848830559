import React from 'react';
import PropTypes from 'prop-types';

import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';

import * as styles from './ManagerContact.module.scss';

import Whatsapp from '../../../../../icons/whatsapp-new.svg';
import Telegram from '../../../../../icons/telegram.svg';

const ManagerContactComponent = ({ contact }) => {
  const {
    image,
    name,
    position,
    phone,
    messengers,
    email
  } = contact;

  return (
    <div className={styles.ManagerContact}>
      <div className={styles.ManagerContact__imageWrap}>
        <img className={styles.ManagerContact__image} src={image} alt={name} />
      </div>
      <div className={styles.ManagerContact__name}>{name}</div>
      <div className={styles.ManagerContact__position}>{position}</div>
      <a rel='noopener noreferrer' className={styles.ManagerContact__phone} href={`tel:${phone}`} target='_blank'>
        {phone}
      </a>
      <div className={styles.ManagerContact__messengers}>
        <a
          rel='noopener noreferrer'
          className={styles.ManagerContact__messengerLink}
          href={messengers.whatsapp}
          target='_blank'
        >
          <IconSvg icon={Whatsapp} />
        </a>
        <a
          rel='noopener noreferrer'
          className={styles.ManagerContact__messengerLink}
          href={messengers.telegram}
          target='_blank'
        >
          <IconSvg icon={Telegram} />
        </a>
      </div>
      <a rel='noopener noreferrer' className={styles.ManagerContact__email} href={`mailto:${email}`} target='_blank'>
        {email}
      </a>
    </div>
  );
};

ManagerContactComponent.propTypes = {
  contact: PropTypes.shape({
    phone: PropTypes.string,
    messengers: PropTypes.shape({
      whatsapp: PropTypes.string,
      telegram: PropTypes.string
    }),
    email: PropTypes.string,
    vkUrl: PropTypes.string,
    instagramUrl: PropTypes.string
  })
};

ManagerContactComponent.defaultProps = {
  contact: {
    phone: '',
    messangers: {
      whatsapp: '',
      telegram: ''
    },
    email: '',
    vkUrl: 'https://vk.com',
    instagramUrl: 'https://instagram.com'
  }
};

export default ManagerContactComponent;
