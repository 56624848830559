import React, { useState, useEffect } from 'react';


const Timer = ({ initialCounter, onTimeEnd }) => {
  const [counter, setCounter] = useState(initialCounter);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    if (counter === 0) {
      onTimeEnd();
    }

    return () => clearInterval(timer);
  }, [counter]);

  return (
    <span>{counter}</span>
  );
};

export default Timer;
