import { useState, useEffect, useRef } from 'react';

const useWs = (orderId) => {
  let requestParams = null;
  const hostName = window.location.hostname.split('.').slice(-2).join('.');
  const ip = window.location.hostname;

  const [isReady, setIsReady] = useState(false);
  const [msg, setMsg] = useState(null);

  const ws = useRef(null);

  useEffect(() => {
    if (hostName === 'localhost') {
      requestParams = 'ws://127.0.0.1:9222/?';
    } else if (hostName === 'spotway.ru') {
      requestParams = `wss://${hostName}/notify?`;
    } else if (ip === '95.213.204.29') {
      requestParams = `ws://${ip}/notify?`;
    } else if (ip === '1.0.4.59') {
      requestParams = `ws://${ip}:9222/?`;
    }

    requestParams = requestParams.concat(`key${orderId}=${orderId}&`);
    const socket = new WebSocket(requestParams);

    // console.log('requestParams => ', requestParams);
    socket.onopen = () => setIsReady(true);
    socket.onclose = () => setIsReady(false);
    socket.onmessage = (event) => {
      // console.log(JSON.parse(JSON.parse(event.data)));
      setMsg(JSON.parse(JSON.parse(event.data)));
    };

    ws.current = socket;

    return () => {
      socket.close();
    };
  }, []);

  // bind is needed to make sure `send` references correct `this`
  return [isReady, msg, ws.current?.send.bind(ws.current)];
};

export default useWs;
