const getBuildData = async (viewId, callback) => {
  try {
    const response = await fetch(`/api/v2/build_data?view=${viewId}`, {
      method: 'GET'
    });

    const result = await response.json();

    if (response.ok) {
      callback(result.buildData);
    }
  } catch (error) {
    console.log(error);
  }
};

const getOfferData = async (offerId, viewId, callback) => {
  try {
    const response = await fetch(`/api/v2/offers/${offerId}.json?view=${viewId}`, {
      method: 'GET'
    });

    const result = await response.json();

    if (response.ok) {
      callback(result.offer);
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchBuildData = (result) => {
  getBuildData('main', (callback) => {
    result(callback);
  });
};

export const fetchReferalBuildData = (result) => {
  getBuildData('referal', (callback) => {
    result(callback);
  });
};

export const fetchFooterData = (result) => {
  getBuildData('footer', (callback) => {
    result(callback);
  });
};

export const fetchHeaderData = (result) => {
  getBuildData('header', (callback) => {
    result(callback);
  });
};

export const fetchEvensOfferData = ((offerId, result) => {
  getOfferData(offerId, 'events', (callback) => {
    result(callback);
  });
});

export const fetchFaqOfferData = ((offerId, result) => {
  getOfferData(offerId, 'faq', (callback) => {
    result(callback);
  });
});
