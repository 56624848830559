import React, { useRef, useEffect, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import Cleave from 'cleave.js/react';

import FormElementLabel from '../FormElementLabel';

import { isBrowser } from '../../../../helpers/browser';

import * as styles from './TextInput.module.scss';

const paddingValue = value => `calc(${value}px + var(--spacing-form-element-horizontal))`;
const paddingValueFrom = (el) => {
  const { width } = el.getBoundingClientRect();
  return paddingValue(width);
};

export const TextInputProps = {
  labelText: PropTypes.string || PropTypes.undefined,
  preContent: React.ReactNode,
  afterContent: React.ReactNode,
  className: PropTypes.string,
  error: PropTypes.string || PropTypes.undefined,
  mask: PropTypes.any,
  ref: PropTypes.any
};

const TextInput = forwardRef((props, forwardedRef) => {
  const {
    labelText,
    preContent,
    afterContent,
    className,
    error,
    mask,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref,
    ...otherInputProps
  } = props;

  const inputRef = useRef(null);
  const preContentRef = useRef(null);
  const afterContentRef = useRef(null);
  const [cleaveValue, setCleaveValue] = useState('');
  const [cleaveRawValue, setCleaveRawValue] = useState('');

  const numericPhone = (_phone) => {
    const inputPhone = _phone?.replace(/[^0-9]/g, '') || '';

    if (inputPhone.length >= 10) {
      return inputPhone.slice(-10);
    }

    return '';
  };

  const normalizePhone = (_phone) => {
    const inputPhone = numericPhone(_phone);

    if (inputPhone) return `+7${inputPhone}`;

    return '';
  };

  const maskPhone = (_phone) => {
    const inputPhone = numericPhone(_phone);

    if (inputPhone) {
      return `+7(${inputPhone.slice(0, 3)})${inputPhone.slice(3, 6)}-${inputPhone.slice(6, 8)}${inputPhone.slice(8, 10)}`;
    }

    return '';
  };

  const onPhoneChange = (e) => {
    if (!otherInputProps.phone) return;
    e.stopPropagation();

    const inputPhone = numericPhone(e.data);

    if (inputPhone) {
      const cleave = e.target;
      const rawValue = normalizePhone(inputPhone);
      const value = maskPhone(inputPhone);

      setCleaveValue(value);
      setCleaveRawValue(rawValue);
      cleave.value = value;
      cleave.rawValue = rawValue;
    }
  };

  useEffect(() => {
    if (isBrowser && inputRef.current) {
      const input = inputRef.current;

      if (preContentRef.current) {
        input.style.paddingLeft = paddingValueFrom(preContentRef.current);
      }
      if (afterContentRef.current) {
        input.style.paddingRight = paddingValueFrom(afterContentRef.current);
      }
    }
  }, [preContentRef.current, afterContentRef.current]);

  useEffect(() => {
    if (isBrowser && inputRef.current) {
      const input = inputRef.current;

      if (otherInputProps.value && otherInputProps.phone) {
        console.log('otherInputProps => ', otherInputProps);
        const rawValue = normalizePhone(otherInputProps.value);
        const value = maskPhone(otherInputProps.value);

        setCleaveValue(value);
        setCleaveRawValue(rawValue);
        input.value = value;
        input.rawValue = rawValue;
      }
    }
  }, [otherInputProps.value]);

  return (
    <div className={styles.TextInput}>
      <label htmlFor={otherInputProps.id || otherInputProps.name}>
        <FormElementLabel
          className={cn({ [styles.TextInput__bold]: otherInputProps.labelBold })}
        >
          {labelText}
        </FormElementLabel>

        <div
          className={cn(styles.TextInput__inputContainer, {
            [styles.TextInput__inputContainer_withError]: error
          })}
        >
          {preContent && (
            <div className={styles.TextInput__preContent} ref={preContentRef}>
              {preContent}
            </div>
          )}

          {mask && otherInputProps.phone && (
            <Cleave
              className={cn(styles.TextInput__input, className)}
              options={mask}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...otherInputProps}
              htmlRef={(e) => {
                inputRef.current = e;
                if (typeof forwardedRef === 'function') {
                  forwardedRef(e);
                }
              }}
              onBeforeInput={onPhoneChange}
              value={cleaveValue}
              rawValue={cleaveRawValue}
            />
          )}

          {mask && !otherInputProps.phone && (
            <Cleave
              className={cn(styles.TextInput__input, className)}
              options={mask}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...otherInputProps}
              htmlRef={(e) => {
                inputRef.current = e;
                if (typeof forwardedRef === 'function') {
                  forwardedRef(e);
                }
              }}
            />
          )}

          {!mask && !otherInputProps.phone && (
            <input
              className={cn(styles.TextInput__input, className)}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...otherInputProps}
              ref={(e) => {
                inputRef.current = e;
                if (typeof forwardedRef === 'function') {
                  forwardedRef(e);
                }
              }}
            />
          )}

          {afterContent && (
            <div
              className={styles.TextInput__afterContent}
              ref={afterContentRef}
            >
              {afterContent}
            </div>
          )}
        </div>
      </label>

      {error ? (
        <div className={styles.TextInput__errorMessage}>{error}</div>
      ) : null}
    </div>
  );
});

TextInput.propTypes = {
  labelText: (PropTypes.string || PropTypes.undefined),
  preContent: PropTypes.element,
  afterContent: PropTypes.element,
  className: PropTypes.string,
  error: (PropTypes.string || PropTypes.undefined),
  mask: PropTypes.node,
  ref: PropTypes.node
};

TextInput.defaultProps = {
  labelText: undefined,
  preContent: null,
  afterContent: null,
  className: '',
  error: undefined,
  mask: null,
  ref: null
};

export default TextInput;
