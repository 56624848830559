import React, { useRef, useEffect, useState } from 'react';

import { isBrowser } from '../../../../helpers/browser';
import { AuthProvider } from '../../../../hooks/use-auth';

import Layout from '../Layout/Layout';
import OffersSection from './OffersSection';
import FeaturesSection from './FeaturesSection/FeaturesSection';
import LogoSection from './LogoSection/LogoSection';

import MediaSection from './MediaSection';
import PressSection from './PressSection';
import PartnersSection from './PartnersSection/PartnersSection';

import { fetchBuildData } from '../../../../services/base';

const HASH_LINKS = {
  about: 'about',
  offers: 'offers'
};

const MainPage = () => {
  const aboutRef = useRef(null);
  const offersRef = useRef(null);
  const [partnersData, setPartnersData] = useState([]);
  const [contactsData, setContactsData] = useState([]);
  const [pressData, setPressData] = useState([]);
  const [featuresData, setFeaturesData] = useState([]);
  const [offersData, setOffersData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [aboutData, setAboutData] = useState('');

  useEffect(() => {
    fetchBuildData((result) => {
      setPartnersData(result.partners);
      setContactsData(result.contacts);
      setPressData(result.press);
      setFeaturesData(result.features);
      setOffersData(result.offers);
      setVideoData(result.videos);
      setAboutData(result.about);
    });

    if (isBrowser) {
      const urlHash = window.location.hash;

      if (urlHash.includes(HASH_LINKS.about) && aboutRef.current) {
        aboutRef.current.scrollIntoView();
      } else if (urlHash.includes(HASH_LINKS.offers) && offersRef.current) {
        offersRef.current.scrollIntoView();
      }
    }
  }, []);

  return (
    <AuthProvider>
      <Layout withBackground>
        <OffersSection ref={offersRef} offers={offersData} />
        <FeaturesSection features={featuresData} />
        <LogoSection ref={aboutRef} bubbleText={aboutData} />
        <MediaSection contents={videoData} />
        <PressSection items={pressData} />
        <PartnersSection
          partners={partnersData.filter(f => f.on_main_page)}
          contact={contactsData.forPartners}
        />
      </Layout>
    </AuthProvider>
  );
};

export default MainPage;
