import React, { useContext, useEffect } from 'react';
// import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { OrderContext } from '../../../context/OrderContext';
import Select from '../../Select';
import FormsRow from '../../FormsRow';
import TextInput from '../../TextInput';
import useDebounce from '../../../../../hooks/use-debounce';

import * as styles from './LearnedFromType.module.scss';

const LearnedField = {
  friends: 'ФИО ДРУГА ИЛИ КОДОВОЕ СЛОВО?',
  social_site: 'ВК, ОК, Телеграм или свой вариант?',
  variant: 'Где именно?'
};

const LearnedFromType = () => {
  const { order, learnedFromType, handleUpdateOrder } = useContext(OrderContext);
  const submitRef = React.createRef();

  const {
    register,
    watch,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChanges',
    reValidateMode: 'onChange',
    defaultValues: {
      learnedType: order.learned_from_type,
      learnedFrom: order.learned_from
    }
  });

  const learnedType = watch('learnedType');
  const learnedFrom = watch('learnedFrom');

  const deferredLearnedType = useDebounce(learnedType, 1000);
  const deferredLearnedFrom = useDebounce(learnedFrom, 1000);

  const options = (
    Object.entries(learnedFromType).map(([k, v]) => (
      <option key={`option-${k}`} value={k}>{v}</option>
    ))
  );

  useEffect(() => {
    if (order.learned_from_type === learnedType
      && order.learned_from === learnedFrom) return;

    if (deferredLearnedType && !deferredLearnedFrom && !LearnedField[deferredLearnedType]) {
      submitRef.current.click();
    }
    if (deferredLearnedType && deferredLearnedFrom && LearnedField[deferredLearnedType]) {
      submitRef.current.click();
    }
  }, [deferredLearnedType, deferredLearnedFrom]);

  const handleLearnedType = (e) => {
    const { value } = e.currentTarget;
    setValue('learnedType', value);

    if (LearnedField[value]) {
      setError('learnedFrom', { message: 'Необходимо заполнить' });
    } else {
      setValue('learnedFrom', '');
    }
  };

  const handleLearnedFrom = (e) => {
    const { value } = e.currentTarget;

    if (value) {
      setValue('learnedFrom', value);
    } else {
      setValue('learnedFrom', '');
      setError('learnedFrom', { message: 'Необходимо заполнить' });
    }
  };

  const onSubmit = (formData) => {
    console.log('onSubmit => ', formData);
    // alert(`Sending: ${JSON.stringify(formData)}`);

    const params = {
      order: {
        learned_from_type: formData.learnedType,
        learned_from: formData.learnedFrom
      }
    };

    handleUpdateOrder(params, (orderResult) => {
      console.log(orderResult);
    });
  };

  return (
    <form
      className={styles.LearnedFromType}
      onSubmit={handleSubmit(data => onSubmit(data))}
    >
      <div>
        <FormsRow>
          <Select
            className={styles.LearnedFromType__select}
            labelText='Откуда вы узнали о туре?'
            name='learned_from_type'
            {...register('learnedType')}
            error={errors.learnedType?.message}
            value={learnedType}
            onChange={handleLearnedType}
          >
            {options}
          </Select>
        </FormsRow>

        <FormsRow>
          {LearnedField[learnedType] && (
            <TextInput
              className={styles.LearnedFromType__select}
              labelText={LearnedField[learnedType]}
              name='learned_from'
              {...register('learnedFrom')}
              error={errors.learnedFrom?.message}
              value={learnedFrom}
              onChange={handleLearnedFrom}
            />
          )}
        </FormsRow>
        <input type='submit' ref={submitRef} style={{ display: 'none' }} />
      </div>
    </form>
  );
};

LearnedFromType.propTypes = {

};

export default LearnedFromType;
