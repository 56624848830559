import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FreeMode, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';

import * as styles from './Slider.module.scss';

import ChevronLeftIcon from '../../../../../icons/chevron-left.svg';
import ChevronRightIcon from '../../../../../icons/chevron-right.svg';

const Slider = ({ contents }) => {
  if (contents === undefined || contents.length === 0) return '';

  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);
  const [paginationEl, setPaginationEl] = useState(null);

  return (
    <section className={styles.Slider}>
      <Swiper
        className={styles.Swiper}
        grabCursor
        slidesPerView='auto'
        modules={[FreeMode, Pagination, Navigation]}
        navigation={{
          prevEl,
          nextEl,
          disabledClass: styles.Slider__navDisabled,
          lockClass: styles.Slider__navLock
        }}
        pagination={{
          el: paginationEl,
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 5
        }}
      >
        {contents.map(content => (
          <SwiperSlide key={`content_slider-${content.url}`} className={styles.Swiper__slide}>
            <div className={styles.Swiper__slideInner}>
              {content.type === 'video' && (
                <>
                  <div className={styles.Swiper__slideVideoOverlay} />
                  <iframe
                    src={content.url}
                    title={content.title}
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  />
                </>
              )}
              {content.type === 'image' && (
                <img
                  src={content.url}
                  title={content.title}
                  alt={content.info}
                  frameBorder='0'
                  allowFullScreen
                />
              )}
            </div>
          </SwiperSlide>
        ))}
        <div
          className={styles.Slider__navLeft}
          ref={node => setPrevEl(node)}
        >
          <IconSvg icon={ChevronLeftIcon} className={styles.Slider__chevron} />
        </div>
        <div
          className={styles.Slider__navRight}
          ref={node => setNextEl(node)}
        >
          <IconSvg icon={ChevronRightIcon} className={styles.Slider__chevron} />
        </div>
      </Swiper>
      <div
        className={styles.Slider__navPagination}
        ref={node => setPaginationEl(node)}
      />
    </section>
  );
};

Slider.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string
  })).isRequired
};

export default Slider;
