export default function formSubmit(form) {
  const formData = new FormData(form);
  let data;

  const xhr = new XMLHttpRequest();
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      data = JSON.parse(xhr.responseText);
    }
  };

  xhr.open(form.method, form.action, false);
  xhr.send(formData);

  return data;
}
