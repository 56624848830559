import { defaultDateFormat } from './dates';

export const nullableString = (value, originalValue) => {
  const prepareValue = value?.length === 3 ? value?.replace('+7(', '') : value;
  return prepareValue === '' ? null : originalValue;
};

export const nullableDate = (value, originalValue) => {
  return [null, ''].includes(originalValue) ? null : defaultDateFormat(value, originalValue);
};
