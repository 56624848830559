import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Layout from '../../Layout';
import { AuthProvider } from '../../../../../hooks/use-auth';
import { fetchBuildData } from '../../../../../services/base';
import { OrderProvider } from '../../../context/OrderContext';
import BaseShellContainer from '../../BaseShellContainer';
import OrderHeader from './OrderHeader';
import Sidebar from '../Sidebar';
import Section from '../Sections';

import * as styles from './OrderSection.module.scss';
import { MenuItems, ORDER_ITEMS } from '../../MenuItems';
import HintPreview from '../../HintPreview/HintPreview';

const OrderSection = ({ orderId, tab, notifyOfPay }) => {
  const [contactsData, setContactsData] = useState([]);
  const [offersData, setOffersData] = useState([]);

  useEffect(() => {
    fetchBuildData((result) => {
      setContactsData(result.contacts);
      setOffersData(result.offers);
    });
  }, []);

  return (
    <AuthProvider>
      <Layout withBackground contacts={contactsData.forTours} offers={offersData}>
        <BaseShellContainer>
          <OrderProvider id={orderId} tab={ORDER_ITEMS[tab] || 'orders'} notifyOfPay={notifyOfPay}>
            <section className={styles.OrderSection} id='orderSection'>
              <OrderHeader />
              <div className={styles.OrderSection__layout}>
                <div className={styles.OrderSection__orderModuleWrap}>
                  <Section />
                </div>
                <div className={styles.OrderSection__sidebarWrap}>
                  <Sidebar />
                </div>
              </div>

              <div className={styles.OrderSection__bottomMenuWrap}>
                <MenuItems />
              </div>
            </section>
            <HintPreview tab={ORDER_ITEMS[tab] || 'orders'} />
          </OrderProvider>
        </BaseShellContainer>
      </Layout>
    </AuthProvider>
  );
};


OrderSection.propTypes = {
  orderId: PropTypes.number.isRequired,
  tab: PropTypes.string,
  notifyOfPay: PropTypes.string
};

OrderSection.defaultProps = {
  tab: 'orders',
  notifyOfPay: ''
};

export default OrderSection;
