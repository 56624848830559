import React, {
  createContext,
  useMemo,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';

import {
  fetchOrderData,
  fetchSubOrderData,
  fetchAllSubOrderData,
  updateMember,
  updateOrder,
  updateMemberClarifications,
  addPromoCode
} from '../../../services/order';

const OrderContext = createContext({});

const OrderProvider = ({
  id,
  tab,
  notifyOfPay,
  children
}) => {
  const [itemMenu, setItemMenu] = useState('');
  const [viewedHints, setViewedHints] = useState([]);
  const [hasNotifyOfPay, setHasNotifyOfPay] = useState(notifyOfPay);
  const [order, setOrder] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [orderDocuments, setOrderDocuments] = useState([]);
  const [orderMembers, setOrderMembers] = useState([]);
  const [subOrders, setSubOrders] = useState([]);
  const [allSubOrders, setAllSubOrders] = useState([]);
  const [learnedFromType, setLearnedFromType] = useState({});
  const [accommodateWithWish, setAccommodateWithWish] = useState({});
  const [requestBedType, setRequestBedType] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [isOrderPaid, setIsOrderPaid] = useState(false);
  const [orderId] = useState(id);

  const handleMenuItemChange = (currentItem, callback) => {
    setItemMenu(currentItem);
    callback(currentItem, viewedHints);
    setViewedHints([...viewedHints, currentItem]);
  };

  const handleUpdateMember = (formData, memberId, result) => {
    setIsLoading(true);

    updateMember(`/api/v2/members/${memberId}.json`, formData, memberId, (callback) => {
      // console.log('handleUpdateMember => ', callback);
      if (callback.status === 'ok') {
        setOrderMembers(callback.order.members);
      }
      setIsLoading(false);
      result(callback);
    });
  };

  const handleUpdateMemberClarifications = (formData, result) => {
    setIsLoading(true);

    updateMemberClarifications('/api/v2/clarifications.json', formData, (callback) => {
      // console.log('updateMemberClarifications => ', callback);
      if (callback.status === 'ok') {
        setAllSubOrders(callback.order.up_all_sub_orders);
        setSubOrders(callback.order.up_sub_orders);
      }
      setIsLoading(false);
      result(callback);
    });
  };

  const handleAddPromoCode = (formData, result) => {
    addPromoCode(formData, (callback) => {
      // console.log('handleAddPromoCode => ', callback);
      if (callback.status === 'ok') {
        setOrder(callback.order);
      }
      result(callback);
    });
  };

  const addMemberToSubOrder = (subOrderPeopleSelect, memberIds, result) => {
    setIsLoading(true);

    const params = {
      order: {
        sub_orders_attributes: {
          '0': {
            id: subOrderPeopleSelect.subOrderId,
            order_id: orderId,
            sub_offer_id: subOrderPeopleSelect.subOfferId,
            name: subOrderPeopleSelect.subOrderName,
            member_ids: memberIds
          }
        }
      }
    };

    if (subOrderPeopleSelect.subOrderId) {
      params['order']['sub_orders_attributes']['0']['id'] = subOrderPeopleSelect.subOrderId;
    }

    updateOrder(subOrderPeopleSelect.orderUrl, params, (orderResult) => {
      if (orderResult.status === 'ok') {
        setAllSubOrders(orderResult.order.up_all_sub_orders);
        setSubOrders(orderResult.order.up_sub_orders);

        setOrder(orderResult.order);
        setIsOrderPaid((orderResult.order.total_cost || 0) <= (orderResult.order.amount_paid || 0));
      }
      setIsLoading(false);
      result(orderResult);
    });
  };

  const handleUpdateOrder = (formData, result) => {
    // setIsLoading(true);

    updateOrder(`orders/${order.pnr}`, formData, (callback) => {
      // console.log('handleUpdateOrder => ', callback);
      if (callback.status === 'ok') {
        setOrder(callback.order);
        setIsOrderPaid((callback.order.total_cost || 0) <= (callback.order.amount_paid || 0));
      }
      // setIsLoading(false);
      result(callback);
    });
  };

  const handleOrderSelfCancel = (formData, result) => {
    setIsLoading(true);

    updateOrder(`orders/${order.pnr}/self_cancel`, formData, (callback) => {
      // console.log('handleOrderSelfCancel => ', callback);
      if (callback.status === 'ok') {
        setOrder(callback.order);
        setIsOrderPaid((callback.order.total_cost || 0) <= (callback.order.amount_paid || 0));
      }
      setIsLoading(false);
      result(callback);
    });
  };

  const handleInviteUser = (formData, result) => {
    setIsLoading(true);

    updateOrder(`/orders/${order.pnr}/invite`, formData, (callback) => {
      // console.log('handleInviteUser => ', callback);
      if (callback.status === 'ok') {
        setOrder(callback.order);
        setOrderMembers(callback.order.members);
      }
      setIsLoading(false);
      result(callback);
    });
  };

  useEffect(() => {
    // console.log('context orderId => ', orderId);
    setIsLoading(true);

    fetchAllSubOrderData(orderId, (subAllOrdersResult) => {
      // console.log(subAllOrdersResult.sub_orders);
      setAllSubOrders(subAllOrdersResult.sub_orders);
    });

    fetchOrderData(orderId, (result) => {
      // console.log(result);
      setOrder(result);
      setItemMenu(tab);
      setOrderItems(result.order_items);
      setOrderDocuments(result.documents);
      setOrderMembers(result.members);
      setLearnedFromType(result.enum_learned_from_type);
      setAccommodateWithWish(result.enum_accommodate_with_wish_type);
      setRequestBedType(result.enum_bed_types);
      setIsOrderPaid((result.total_cost || 0) <= (result.amount_paid || 0));

      fetchSubOrderData(orderId, (subOrdersResult) => {
        // console.log(subOrdersResult.sub_orders);
        setSubOrders(subOrdersResult.sub_orders);
        setIsLoading(false);
      });
    });
  }, [orderId]);

  const memoedValue = useMemo(
    () => ({
      orderId,
      isOrderPaid,
      isLoading,
      itemMenu,
      order,
      orderItems,
      orderDocuments,
      orderMembers,
      subOrders,
      allSubOrders,
      learnedFromType,
      accommodateWithWish,
      requestBedType,
      handleMenuItemChange,
      handleUpdateMember,
      addMemberToSubOrder,
      handleUpdateOrder,
      handleOrderSelfCancel,
      handleInviteUser,
      handleUpdateMemberClarifications,
      handleAddPromoCode,
      hasNotifyOfPay,
      setHasNotifyOfPay
    }),
    [
      isOrderPaid,
      isLoading,
      itemMenu,
      order,
      orderItems,
      orderDocuments,
      orderMembers,
      subOrders,
      allSubOrders,
      learnedFromType,
      accommodateWithWish,
      requestBedType,
      handleMenuItemChange,
      handleUpdateMember,
      addMemberToSubOrder,
      handleUpdateOrder,
      handleOrderSelfCancel,
      handleInviteUser,
      handleUpdateMemberClarifications,
      handleAddPromoCode,
      hasNotifyOfPay,
      setHasNotifyOfPay
    ]
  );

  return (
    <OrderContext.Provider value={memoedValue}>
      {children}
    </OrderContext.Provider>
  );
};

OrderProvider.propTypes = {
  id: PropTypes.number.isRequired,
  tab: PropTypes.string,
  notifyOfPay: PropTypes.string,
  children: PropTypes.element.isRequired
};

OrderProvider.defaultProps = {
  tab: 'orders',
  notifyOfPay: ''
};

export {
  OrderProvider,
  OrderContext
};
