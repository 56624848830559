import headersWithToken from '../helpers/header';
import { isBrowser } from '../helpers/browser';

const USER_IDENTIFIER = 'spotwayUser';
const TOKEN_USER_IDENTIFIER = 'spotwayUserToken';

const setToken = (token) => {
  if (isBrowser) {
    window.localStorage.setItem(TOKEN_USER_IDENTIFIER, token);
  }
  return true;
};

const getToken = () => {
  if (isBrowser) return window.localStorage.getItem(TOKEN_USER_IDENTIFIER);

  return null;
};

const setUser = (user) => {
  if (isBrowser) {
    window.localStorage.setItem(USER_IDENTIFIER, JSON.stringify(user));
  }
  return true;
};

const registration = async ({ phone, gRecaptchaResponse }, callback) => {
  const data = {
    'user': {
      'phone': phone
    },
    'g-recaptcha-response': gRecaptchaResponse
  };

  try {
    const response = await fetch('/api/v2/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

const resendSms = async ({ phone, gRecaptchaResponse }, callback) => {
  const data = {
    'user': {
      'phone': phone
    },
    'g-recaptcha-response': gRecaptchaResponse
  };

  try {
    const response = await fetch('/api/v2/users/resend_sms', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

const login = async ({ phone, password }, callback) => {
  const data = {
    'user': {
      'password': password,
      'phone': phone
    }
  };

  try {
    const response = await fetch('/login.json', {
      method: 'POST',
      headers: headersWithToken(),
      body: JSON.stringify(data)
    });

    const result = await response.json();

    if (response.ok) {
      setUser(result.user);
      setToken(response.headers.get('Authorization'));
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

const logout = async (callback) => {
  const token = getToken();

  if (!token) return;

  try {
    const response = await fetch('/logout.json', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    const result = await response.json();

    if (response.ok) {
      setUser({});
      setToken(null);
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getUser = () => (
  isBrowser && window.localStorage.getItem(USER_IDENTIFIER)
    ? JSON.parse(window.localStorage.getItem(USER_IDENTIFIER))
    : {}
);

export const handleLogin = ({ phone, password }, result) => {
  login({ phone, password }, (callback) => {
    let loggedIn = false;

    if (callback.user) loggedIn = true;

    result({ ...callback, loggedIn });
  });
};

export const isLoggedIn = () => {
  const user = getUser();
  return !!user.phone;
};

export const handleLogout = (result) => {
  logout((callback) => {
    result(callback);
  });
};

export const handleRegistration = ({ phone, gRecaptchaResponse }, result) => {
  registration({ phone, gRecaptchaResponse }, (callback) => {
    let isRegistered = false;

    if (callback.user_exists || callback.new_user) isRegistered = true;

    result({ ...callback, isRegistered });
  });
};

export const handleResendSms = ({ phone, gRecaptchaResponse }, result) => {
  resendSms({ phone, gRecaptchaResponse }, (callback) => {
    result({ ...callback });
  });
};

export const handleRefresh = (userData, result) => {
  setUser(userData);
  result({ isRefreshed: true });
};

export const clearLocalStorage = () => {
  setUser({});
  setToken(null);
};

export const refreshLocalStorage = (userData) => {
  setUser(userData);
  setToken(null);
};
