import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';
import usePopup from '../../../../hooks/use-popup';
import Popup from '../Popup/Popup';
import Action from '../Action/Action';
// import lightenDarkenColor from '../../../../helpers/lightenDarkenColor';
import { numberToMoney } from '../../../../helpers/currency';

// import CalendarIcon from '../../../../../icons/calendar-new.svg';

import * as styles from './OfferCard.module.scss';

const compI18n = {
  whatIncludeLink: 'Что включено?',
  priceFrom: (offer) => {
    if (!offer.min_cost) return 'Как это было?';
    if (typeof offer.min_cost === 'number') return `от ${numberToMoney(offer.min_cost, offer.currency)}`;
    if (typeof offer.min_cost === 'string') return offer.min_cost;
  },
  moreButton: 'Подробнее'
};

export const OfferCardData = {
  spot: PropTypes.string,
  period: PropTypes.string,
  name: PropTypes.string,
  min_cost: PropTypes.number,
  description: PropTypes.string,
  image: PropTypes.any,
  slug: PropTypes.string,
  offer_showing_period: PropTypes.string,
  colors: {
    btn_main_photo_color: {
      hex: PropTypes.string
    }
  },
  coverImage: PropTypes.string
};

const OfferCardComponent = ({ offer, compactVariant, desktopVariant }) => {
  const [isIncludePopupOpen, openIncludePopup, closeIncludePopup] = usePopup();
  const imageSrc = useRef(null);

  const getBlobImage = async (url) => {
    if (compactVariant) return;

    try {
      await fetch(url, {
        method: 'GET'
      })
        .then(response => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          if (!blobUrl) return;

          imageSrc.current.src = blobUrl;
        });
    } catch (error) {
      console.log(error);
    }
  };

  const minPriceText = compI18n.priceFrom(offer);
  // const offerBrandColor = offer.colors.btn_main_photo_color.hex;
  // const offerDarkenBrandColor = lightenDarkenColor(offerBrandColor, -35);
  // const buttonBackground = offer.min_cost ? `linear-gradient(180deg, ${offerBrandColor} 0%, ${offerDarkenBrandColor} 100%)` : 'none';
  const buttonBackground = offer.min_cost ? 'var(--color-yellow-1)' : 'none';
  const buttonBorder = offer.min_cost ? '' : { border: '1px solid var(--color-grey-2)' };

  useEffect(() => {
    getBlobImage(offer.cover_image);
  }, []);

  return (
    <>
      {!desktopVariant && (
        <div className={styles.OfferCard}>
          {!compactVariant && (
            <Action
              className={styles.OfferCard__image}
              href={`/${offer.slug}`}
              themes={['Link']}
            >
              <img
                ref={imageSrc}
                alt=''
                className={styles.OfferCard__coverImage}
              />
            </Action>
          )}

          <Action
            className={styles.OfferCard__name}
            href={`/${offer.slug}`}
            themes={['Link']}
          >
            {offer.name}
          </Action>

          <Action
            className={styles.OfferCard__spot}
            href={`/${offer.slug}`}
            themes={['Link']}
          >
            {offer.spot}
          </Action>

          <div className={styles.OfferCard__info}>
            <Action
              className={styles.OfferCard__periodLink}
              href={`/${offer.slug}`}
              themes={['Link']}
            >
              <div className={styles.OfferCard__periodWrap}>
                {/* <IconSvg icon={CalendarIcon} className={styles.OfferCard__iconCalendar} /> */}
                {offer.offer_showing_period}
              </div>
            </Action>

            {!compactVariant && (
              <div className={styles.OfferCard__priceWrap}>
                <Action
                  href={`/${offer.slug}`}
                  themes={['Button', 'ButtonCommon', 'ButtonWarning']}
                  style={{ background: buttonBackground, ...buttonBorder }}
                >
                  {minPriceText}
                </Action>

                {offer.description && (
                  <Action
                    onClick={openIncludePopup}
                    themes={['Link']}
                    className={styles.OfferCard__priceInclude}
                  >
                    {compI18n.whatIncludeLink}
                  </Action>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {desktopVariant && (
        <div className={styles.OfferCardDesktop}>
          <div
            className={styles.OfferCardDesktop__image}
            // style={{ backgroundImage: `url(${offer.cover_image})` }}
          >
            <img
              // src={offer.cover_image}
              ref={imageSrc}
              alt=''
              className={styles.OfferCard__coverImage}
            />
          </div>
          <div className={styles.OfferCardDesktop__wrap}>
            <Action
              className={styles.OfferCardDesktop__name}
              href={`/${offer.slug}`}
              themes={['Link']}
            >
              {offer.name}
            </Action>

            <Action
              className={styles.OfferCardDesktop__spot}
              href={`/${offer.slug}`}
              themes={['Link']}
            >
              {offer.spot}
            </Action>

            <div className={styles.OfferCardDesktop__info}>
              <Action
                className={styles.OfferCardDesktop__periodLink}
                href={`/${offer.slug}`}
                themes={['Link']}
              >
                <div className={styles.OfferCardDesktop__periodWrap}>
                  {/* <IconSvg icon={CalendarIcon} className={styles.OfferCardDesktop__iconCalendar} /> */}
                  {offer.offer_showing_period}
                </div>
              </Action>

              {!compactVariant && (
                <div className={styles.OfferCardDesktop__priceWrap}>
                  <Action
                    href={`/${offer.slug}`}
                    themes={['Button', 'ButtonCommon', 'ButtonWarning']}
                    style={{ background: buttonBackground, ...buttonBorder }}
                  >
                    {minPriceText}
                  </Action>

                  {offer.description && (
                    <Action
                      onClick={openIncludePopup}
                      themes={['Link']}
                      className={styles.OfferCardDesktop__priceInclude}
                    >
                      {compI18n.whatIncludeLink}
                    </Action>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Popup
        show={isIncludePopupOpen}
        onClose={closeIncludePopup}
        variant='medium'
      >
        <div className={styles.OfferCard__whatIncludeDescription}>
          {offer.description}
        </div>
        <div className={styles.OfferCard__whatIncludeAction}>
          <Action
            href={`/${offer.slug}`}
            themes={['Button', 'ButtonCommon', 'ButtonWarning']}
            style={{ background: buttonBackground }}
          >
            {compI18n.moreButton}
          </Action>
        </div>
      </Popup>
    </>
  );
};

OfferCardComponent.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  compactVariant: PropTypes.bool,
  desktopVariant: PropTypes.bool
};

OfferCardComponent.defaultProps = {
  compactVariant: false,
  desktopVariant: false
};

export default OfferCardComponent;
