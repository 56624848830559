import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import YandexLogoIcon from '../../../../../../icons/yandex_logo.svg';
import SnowflakeIcon from '../../../../../../icons/SW.svg';

import * as styles from './YandexButton.module.scss';
import IconSvg from '../../../../../common/javascript/components/IconSvg/IconSvg';
import Action from '../../Action';

const YandexButton = ({ className, onClick }) => (
  <div
    className={
      cn(
        styles.YandexButton,
        { [className]: className }
      )
    }
  >
    <Action
      className={styles.YandexButton}
      onClick={() => onClick()}
      themes={['Button', 'Fullwidth']}
    >
      <IconSvg icon={YandexLogoIcon} className={styles.YandexButton__ya_logo} />
      <div className={styles.YandexButton__info}>Войти с Яндекс</div>
      <IconSvg icon={SnowflakeIcon} className={styles.YandexButton__avatar} />
    </Action>
  </div>
);

YandexButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

YandexButton.defaultProps = {
  className: ''
};

export default YandexButton;
