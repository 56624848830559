import React, { useState, useEffect } from 'react';

import BaseShellContainer from '../../BaseShellContainer';
import ProfileTabs, { PROFILE_TABS } from '../../ProfileTabs';
import Layout from '../../Layout';
import NoOrders from '../NoOrders';
import OrdersList from '../../OrdersList';
import { AuthProvider } from '../../../../../hooks/use-auth';
import { fetchReferalBuildData } from '../../../../../services/base';
import Loader from '../../Loader';
// import ChpuEvents from '../../ChpuEvents/ChpuEvents';

// import * as styles from './ReferalOrdersPage.module.scss';

const ReferalOrdersPage = () => {
  const [contactsData, setContactsData] = useState([]);
  const [offersData, setOffersData] = useState([]);
  const [ordersData, setOrdersData] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [referalLink, setReferalLInk] = useState({});

  useEffect(() => {
    fetchReferalBuildData((result) => {
      // setReferalLInk(result.referal_link);
      setContactsData(result.contacts);
      setOffersData(result.offers);
      setOrdersData(result.orders.filter(f => f.actual));
      setLoading(false);
    });
  }, []);

  return (
    <AuthProvider>
      <Loader view={loading} />
      <Layout withBackground contacts={contactsData.forTours} offers={offersData}>
        <BaseShellContainer>
          <ProfileTabs currentTab={PROFILE_TABS.referal} />
          {ordersData && ordersData.length > 0 && <OrdersList orders={ordersData} referal />}
          {ordersData && ordersData.length === 0 && <NoOrders offers={offersData} referal />}
          {/* {!loading && <ChpuEvents referalLink={referalLink} />} */}
        </BaseShellContainer>
      </Layout>
    </AuthProvider>
  );
};

export default ReferalOrdersPage;
