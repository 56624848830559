import React, { useContext } from 'react';

import { OrderContext } from '../../../../context/OrderContext';
import IconSvg from '../../../../../../common/javascript/components/IconSvg/IconSvg';
import Action from '../../../Action';

import DocumentsIcon from '../../../../../../../icons/documents.svg';

import * as styles from './Documents.module.scss';

const Documents = () => {
  const {
    order,
    orderDocuments
  } = useContext(OrderContext);

  if (!order.id) return '';

  return (
    <ul className={styles.Documents}>
      {orderDocuments.attach?.map(doc => (
        <li key={doc.name}>
          <Action
            href={doc.url}
            target='_blank'
          >
            <div className={styles.Documents__wrapLink}>
              <IconSvg icon={DocumentsIcon} />
              {doc.name}
            </div>
          </Action>
        </li>
      ))}
      {orderDocuments.insurance?.map(doc => (
        <li key={doc.name}>
          <Action
            href={doc.url}
            target='_blank'
          >
            <div className={styles.Documents__wrapLink}>
              <IconSvg icon={DocumentsIcon} />
              {doc.name}
            </div>
          </Action>
        </li>
      ))}
      {orderDocuments.link_attach?.map(doc => (
        <li key={doc.name}>
          <Action
            href={doc.url}
            target='_blank'
          >
            <div className={styles.Documents__wrapLink}>
              <IconSvg icon={DocumentsIcon} />
              {doc.name}
            </div>
          </Action>
        </li>
      ))}
    </ul>
  );
};

export default Documents;
