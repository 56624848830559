/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import TextInput, { TextInputProps } from '../TextInput';

import * as styles from './DigitPasswordTextInput.module.scss';

const DigitPasswordTextInput = forwardRef((props, ref) => {
  const {
    placeholder,
    maxLength,
    ...otherInputProps
  } = props;

  return (
    <div className={styles.DigitPasswordTextInput}>
      <TextInput
        {...otherInputProps}
        type='text'
        pattern='\d*'
        autoComplete='one-time-code'
        inputMode='numeric'
        placeholder={placeholder}
        maxLength={maxLength}
        ref={ref}
      />
    </div>
  );
});

DigitPasswordTextInput.propTypes = {
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  props: TextInputProps.isRequired
};

DigitPasswordTextInput.defaultProps = {
  placeholder: '••••',
  maxLength: 4
};

export default DigitPasswordTextInput;
