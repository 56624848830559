import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import FormElementLabel from '../FormElementLabel';
import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';

import ChevronDownIcon from '../../../../../icons/chevron-down.svg';

import * as styles from './Select.module.scss';

const Select = forwardRef(({ labelText, error, ...props }, ref) => (
  <>
    <label className={styles.Select} htmlFor={props.id || props.name}>
      <FormElementLabel>{labelText}</FormElementLabel>
      <div className={cn(styles.Select__selectWrap, { [styles.Select__withError]: error })}>
        <select {...props} ref={ref} />
        <IconSvg icon={ChevronDownIcon} />
      </div>
    </label>

    {error && (
      <div className={styles.Select__errorMessage}>{error}</div>
    )}
  </>
));

Select.propTypes = {
  labelText: PropTypes.string,
  error: PropTypes.string
};

Select.defaultProps = {
  labelText: '',
  error: undefined
};

export default Select;
