import React from 'react';
import PropTypes from 'prop-types';

import styles from './SocialButtons.module.scss';

import IconSvg from '../../../../../common/javascript/components/IconSvg/IconSvg';

import Vk from '../../../../../../icons/vk-new.svg';
import Fb from '../../../../../../icons/fb.svg';
import Instagram from '../../../../../../icons/instagram.svg';

const SocialButtons = ({ vkProfile, fbProfile, igProfile }) => {
  return (
    <div className={styles.SocialButtons}>
      {
        true
        && (
          <a href='/auth/vkontakte' target='_blank' rel='noopener noreferrer'>
            <IconSvg icon={Vk} className={styles.SocialButtons__icon} />
          </a>
        )
      }
      {
        false
        && (
          <a href={`https://${fbProfile}`} target='_blank' rel='noopener noreferrer'>
            <IconSvg icon={Fb} className={styles.SocialButtons__icon} />
          </a>
        )
      }
      {
        false
        && (
          <a href={`https://${igProfile}`} target='_blank' rel='noopener noreferrer'>
            <IconSvg icon={Instagram} className={styles.SocialButtons__icon} />
          </a>
        )
      }
    </div>
  );
};

SocialButtons.propTypes = {
  vkProfile: PropTypes.string,
  fbProfile: PropTypes.string,
  igProfile: PropTypes.string
};

SocialButtons.defaultProps = {
  vkProfile: '',
  fbProfile: '',
  igProfile: ''
};

export default SocialButtons;
