// import { navigate } from 'gatsby';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
// import { useHistory, useLocation } from 'react-router-dom';
// import * as sessionsApi from './api/sessions';
// import * as usersApi from './api/users';

import {
  getUser,
  handleLogin,
  handleLogout,
  handleRegistration,
  handleResendSms,
  handleRefresh,
  clearLocalStorage,
  refreshLocalStorage
} from '../services/auth';

import { fetchCurrentUser } from '../services/personal';

const Credentials = {
  phone: PropTypes.string,
  password: PropTypes.string
};

const User = {
  phone: PropTypes.string,
  name: PropTypes.string
};

const AuthContextType = {
  user: User,
  isLoggedIn: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.any,
  notice: PropTypes.any,
  login: ({ phone, password }, callback) => {},
  logout: () => PropTypes.bool,
  registration: ({ phone }, callback) => {},
  resendSms: ({ phone }, callback) => {},
  refresh: (userData, callback) => {}
};

const AuthContext = createContext({ AuthContextType });

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({ children }) {
  const [user, setUser] = useState(User);
  const [error, setError] = useState(null);
  const [notice, setNotice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);

  const isLoggedIn = !!user?.phone || false;
  // We are using `react-router` for this example,
  // but feel free to omit this or use the
  // router of your choice.
  // const history = useHistory();
  // const location = useLocation();

  // If we change page, reset the error state.
  // useEffect(() => {
  //   if (error) setError(null);
  // }, [location.pathname]);

  // Check if there is a currently active session
  // when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  //
  // Finally, just signal the component that the initial load
  // is over.
  // useEffect(() => {
  //   usersApi.getCurrentUser()
  //     .then((user) => setUser(user))
  //     .catch((_error) => {})
  //     .finally(() => setLoadingInitial(false));
  // }, []);
  useEffect(() => {
    // setUser(getUser());
    fetchCurrentUser('personal', (result) => {
      console.log(result);
      clearLocalStorage();
      setUser(result.user);
      refreshLocalStorage(result.user);
      setLoadingInitial(false);
    });
  }, []);

  // Flags the component loading state and posts the login
  // data to the server.
  //
  // An error means that the email/password combination is
  // not valid.
  //
  // Finally, just signal the component that loading the
  // loading state is over.
  // function login(email: string, password: string) {
  //   setLoading(true);

  //   sessionsApi.login({ email, password })
  //     .then((user) => {
  //       setUser(user);
  //       history.push('/');
  //     })
  //     .catch((error) => setError(error))
  //     .finally(() => setLoading(false));
  // }
  const registration = ({ phone, gRecaptchaResponse }, callback) => {
    setLoading(true);

    handleRegistration({ phone, gRecaptchaResponse }, (result) => {
      setNotice(result.notice);
      setError(result.error);

      if (result.isRegistered) {
        setUser({});
      }
      callback(result);
      setLoading(false);
    });
  };

  const login = ({ phone, password }, callback) => {
    setLoading(true);

    handleLogin({ phone, password }, (result) => {
      setNotice(result.notice);
      setError(result.error);

      if (result.loggedIn) {
        setUser(getUser());
        setError(null);
        setNotice(null);
      }

      callback(result);
      setLoading(false);
    });
  };

  const resendSms = ({ phone, gRecaptchaResponse }, callback) => {
    setLoading(true);

    handleResendSms({ phone, gRecaptchaResponse }, (result) => {
      setNotice(result.notice);
      setError(result.error);

      if (result.isRegistered) {
        setUser({});
      }
      callback(result);
      setLoading(false);
    });
  };

  // Sends sign up details to the server. On success we just apply
  // the created user to the state.
  // function signUp(email: string, name: string, password: string) {
  //   setLoading(true);

  //   usersApi.signUp({ email, name, password })
  //     .then((user) => {
  //       setUser(user);
  //       history.push('/');
  //     })
  //     .catch((error) => setError(error))
  //     .finally(() => setLoading(false));
  // }

  // Call the logout endpoint and then remove the user
  // from the state.
  // function logout() {
  //   sessionsApi.logout().then(() => setUser(undefined));
  // }

  const logout = (callback) => {
    handleLogout((result) => {
      setUser({});
      console.log(result);
      callback(result);
    });
  };

  const refresh = (userData) => {
    setLoading(true);

    handleRefresh(userData, (result) => {
      if (result.isRefreshed) {
        setUser(getUser());
        setLoading(false);
      }
    });
  };

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      user,
      isLoggedIn,
      loading,
      error,
      notice,
      login,
      // signUp,
      logout,
      registration,
      resendSms,
      refresh
    }),
    [user, loading, error, notice, refresh]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
