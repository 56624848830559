import * as React from 'react';
import PropTypes from 'prop-types';

import Action from '../../Action';
import Popup from '../../Popup';
import OffersList from '../../OffersList';
import { OfferCardData } from '../../OfferCard';
import usePopup from '../../../../../hooks/use-popup';
import IconSvg from '../../../../../common/javascript/components/IconSvg/IconSvg';

import NoOrderStepsIcon from '../../../../../../icons/no-orders-steps.svg';

import * as styles from './NoOrders.module.scss';

const NoOrders = ({ offers }) => {
  const [isOffersPopupOpen, openOffersPopup, closeOffersPopup] = usePopup();

  return (
    <>
      <div className={styles.NoOrders}>
        <div className={styles.NoOrders__container}>
          <div className={styles.NoOrders__background} />
          <div className={styles.NoOrders__contentContainer}>
            <div className={styles.NoOrders__content}>
              <IconSvg icon={NoOrderStepsIcon} className={styles.NoOrders__stepsImage} />
              <div className={styles.NoOrders__header}>Список заказов пуст</div>
              <Action onClick={openOffersPopup} themes={['Button']}>Выбрать тур</Action>
            </div>
          </div>
        </div>
      </div>

      <Popup
        show={isOffersPopupOpen}
        onClose={closeOffersPopup}
        variant='baseshell'
      >
        <OffersList offers={offers} />
      </Popup>
    </>
  );
};

NoOrders.propTypes = {
  offers: PropTypes.arrayOf(OfferCardData).isRequired
};

export default NoOrders;
