import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import FormsRow from '../../FormsRow';
import TextInput from '../../TextInput';
import DateTextInput from '../../DateTextInput';

import { nullableString, nullableDate } from '../../../../../helpers/yupValidation';
import { fetchCurrentUser } from '../../../../../services/personal';

// import * as styles from './ForeignPassportForm.module.scss';

const FormSchema = Yup.object().shape({
  last_name: Yup.string()
    .trim()
    .transform(nullableString)
    .min(2, 'Должен быть больше одного символа')
    .max(50, '111Должен быть не больше 50 символов')
    .nullable(),
  // .required('Заполни ФАМИЛИЯ'),
  first_name: Yup.string()
    .trim()
    .transform(nullableString)
    .min(2, 'Должен быть больше одного символа')
    .max(50, 'Должен быть не больше 50 символов')
    .nullable(),
  // .required('Заполни ИМЯ'),
  middle_name: Yup.string()
    .trim()
    .transform(nullableString)
    .min(2, 'Должен быть больше одного символа')
    .max(50, 'Должен быть не больше 50 символов')
    .nullable(),
  number: Yup.string()
    .trim()
    .transform(nullableString)
    .min(10, 'Должен быть 10 символов')
    .max(10, 'Должен быть 10 символов')
    .nullable(),
  // .required('Заполни СЕРИЯ И НОМЕР ЗАГРАНПАСПОРТА'),
  expiry: Yup.date()
    .transform(nullableDate)
    .min(new Date(), 'Твой паспорт уже закончился')
    .nullable()
    .typeError('Введи корректную дату ДД.ММ.ГГГГ'),
  // .required('Заполни ДЕЙСТВИТЕЛЕН ДО'),
  nationality: Yup.string()
    .trim()
    .transform(nullableString)
    .min(2, 'Должен быть больше одного символа')
    .max(50, 'Должен быть не больше 50 символов')
    .nullable()
    // .required('Заполни ГРАЖДАНСТВО')
});

const ForeignPassportForm = ({ formId, onSubmit }) => {
  const {
    register,
    watch,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChanges',
    reValidateMode: 'onChange',
    resolver: yupResolver(FormSchema)
  });

  const number = watch('number');
  const expiry = watch('expiry');

  const callbackErrors = (cerrors) => {
    console.log('ForeignPassportForm => ', cerrors);
    Object.entries(cerrors).forEach(([key, value]) => {
      setError(key, { message: value });
    });
  };

  useEffect(() => {
    fetchCurrentUser(formId, (result) => {
      setValue('last_name', result.user.last_name);
      setValue('first_name', result.user.first_name);
      setValue('middle_name', result.user.middle_name);
      setValue('number', result.user.number);
      setValue('expiry', result.user.expiry);
      setValue('nationality', result.user.nationality);
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(data => onSubmit(data, formId, cerrors => callbackErrors(cerrors)))} id={formId}>
      <FormsRow>
        <TextInput
          labelText='Фамилия'
          type='text'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('last_name')}
          error={errors.last_name?.message}
          placeholder='Ivanov'
        />
      </FormsRow>

      <FormsRow>
        <TextInput
          labelText='Имя'
          type='text'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('first_name')}
          error={errors.first_name?.message}
          placeholder='Ivan'
        />
      </FormsRow>

      <FormsRow>
        <TextInput
          labelText='Отчество для авиабилетов'
          type='text'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('middle_name')}
          error={errors.middle_name?.message}
          placeholder='Ivanovich'
        />
      </FormsRow>

      <FormsRow>
        <TextInput
          labelText='Серия и номер загранпаспорта'
          mask={{
            blocks: [2, 8],
            delimiters: [' '],
            numericOnly: true
          }}
          placeholder='76 45880045'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('number')}
          error={errors.number?.message}
          value={number}
        />
      </FormsRow>

      <FormsRow>
        <DateTextInput
          labelText='Действителен до'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('expiry')}
          error={errors.expiry?.message}
          value={expiry}
          placeholder='ДД.ММ.ГГГГ'
        />
      </FormsRow>

      <FormsRow>
        <TextInput
          labelText='Гражданство'
          type='text'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('nationality')}
          error={errors.nationality?.message}
          placeholder='RUS'
        />
      </FormsRow>
    </form>
  );
};

ForeignPassportForm.propTypes = {
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ForeignPassportForm;
