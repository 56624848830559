import React, { useEffect, useState } from 'react';

import cn from 'classnames';
// import { useTranslation } from 'react-i18next';

import useAuth from '../../../../hooks/use-auth';
import usePopup from '../../../../hooks/use-popup';

import { Routes } from '../../../../helpers/routes';
import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';
import Action from '../Action';
import Popup from '../Popup';
import ContactVariants from '../ContactVariants/ContactVariants';
// import BurgerMenu from './BurgerMenu';
// import SubscriptionBanner from '../SubscriptionBanner';
import AuthForm from '../AuthForm';
import OffersList from '../OffersList';

import Logo from '../../../../../icons/logo-lg.svg';
// import Hamburger from '../../../../../icons/hamburger.svg';
// import Close from '../../../../../icons/close-new.svg';
import Person from '../../../../../icons/person.svg';

import { isBrowser } from '../../../../helpers/browser';
import { fetchHeaderData } from '../../../../services/base';

import * as styles from './SiteHeader.module.scss';

const ANCHOR_LINKS = {
  about: 'about',
  offers: 'offers',
  contacts: 'contacts',
  auth: 'auth_phone',
  bonus: 'bonus'
};

const SiteHeader = () => {
  // const { t } = useTranslation('components');
  const { isLoggedIn } = useAuth();
  const [contacts, setContactsData] = useState([]);
  const [offers, setOffersData] = useState([]);

  const [
    isContactsPopupOpen,
    openContactsPopup,
    closeContactsPopup
  ] = usePopup();

  // const [isMobileMenuOpen, openMobileMenu, closeMobileMenu] = usePopup();

  const [isAuthFormOpen, openAuthForm, closeAuthForm] = usePopup();

  const [isOffersPopupOpen, openOffersPopup, closeOffersPopup] = usePopup();

  // const handleMobileMenu = () => {
  //   if (isMobileMenuOpen) {
  //     closeMobileMenu();
  //     return;
  //   }
  //   openMobileMenu();
  // };

  // const handleMobileMenuPopup = (popupName) => {
  //   switch (popupName) {
  //     case 'login':
  //       openAuthForm();
  //       break;
  //     default:
  //       break;
  //   }
  // };

  useEffect(() => {
    fetchHeaderData((result) => {
      setContactsData(result.contacts);
      setOffersData(result.offers);

      const urlLocation = window.location;
      if (urlLocation.pathname.includes(ANCHOR_LINKS.contacts)) {
        openContactsPopup();
      }
      if (urlLocation.pathname.includes(ANCHOR_LINKS.auth)) {
        openAuthForm();
      }
    });
  }, []);

  return (
    <>
      <header className={cn(styles.SiteHeader, styles.SiteHeader_stickied)}>
        <div className={styles.SiteHeader__baseShellContainer}>
          <div className={styles.SiteHeader__inner}>
            <nav
              className={cn(
                styles.SiteHeader__nav,
                styles.SiteHeader__nav_left
              )}
            >
              <Action
                onClick={openOffersPopup}
                className={styles.SiteHeader__navItem}
              >
                {/* {t("SiteHeader.offers")} */}
                Все туры
                <span className={styles.SiteHeader__badge}>{offers.length}</span>
              </Action>

              <Action href={Routes.about} className={styles.SiteHeader__navItem}>
                {/* {t("SiteHeader.about")} */}
                О компании
              </Action>
            </nav>

            <nav
              className={cn(
                styles.SiteHeader__nav,
                styles.SiteHeader__nav_branding
              )}
            >
              {/* <Action
                onClick={handleMobileMenu}
                className={styles.SiteHeader__burgerIcon}
              >
                {!isMobileMenuOpen && <IconSvg icon={Hamburger} />}
                {isMobileMenuOpen && <IconSvg icon={Close} />}
              </Action> */}

              <Action href='/' className={styles.SiteHeader__logoWrap}>
                <IconSvg icon={Logo} className={styles.SiteHeader__logo} />
              </Action>
            </nav>

            <nav
              className={cn(
                styles.SiteHeader__nav,
                styles.SiteHeader__nav_center
              )}
            >
              <Action
                onClick={openOffersPopup}
                className={styles.SiteHeader__navItem}
              >
                {/* {t("SiteHeader.offers")} */}
                Туры
                <span className={styles.SiteHeader__badge}>{offers.length}</span>
              </Action>
            </nav>

            {isBrowser && (
              <nav
                className={cn(
                  styles.SiteHeader__nav,
                  styles.SiteHeader__nav_right
                )}
              >
                {!isLoggedIn && (
                  <>
                    <Action
                      onClick={openAuthForm}
                      className={styles.SiteHeader__navItem}
                    >
                      <div className={styles.SiteHeader__personIcon}>
                        <IconSvg icon={Person} />
                      </div>
                      <div className={styles.SiteHeader__profileLabel}>
                        Войти
                      </div>
                    </Action>
                    <div className={styles.SiteHeader__navItem} />
                  </>
                )}
                {isLoggedIn && (
                  <>
                    <Action
                      href={Routes.account}
                      className={styles.SiteHeader__navItem}
                    >
                      <div className={styles.SiteHeader__personIcon}>
                        <IconSvg icon={Person} />
                      </div>
                      <div className={styles.SiteHeader__profileLabel}>
                        Личный кабинет
                      </div>
                    </Action>

                    <Action
                      href={Routes.orders}
                      className={styles.SiteHeader__navItem}
                    >
                      <div className={styles.SiteHeader__ordersLabel}>
                        {/* {t("SiteHeader.orders")} */}
                        Мои заказы
                      </div>
                      {/* <span className={styles.SiteHeader__badge}>{user?.orders_count}</span> */}
                    </Action>
                  </>
                )}
                {contacts && (
                  <Action
                    className={styles.SiteHeader__contactButton}
                    onClick={openContactsPopup}
                    themes={['Button', 'ButtonSmall', 'ButtonAttention', 'ButtonCommon']}
                  >
                    {/* {t("SiteHeader.contacts")} */}
                    Контакты
                  </Action>
                )}
              </nav>
            )}
          </div>
        </div>
      </header>

      {contacts && (
        <Popup
          show={isContactsPopupOpen}
          onClose={closeContactsPopup}
          variant='small'
        >
          <ContactVariants contacts={contacts} />
        </Popup>
      )}

      {/* <Popup
        show={isMobileMenuOpen}
        onClose={closeMobileMenu}
        variant='mobileMenu'
        withHeader={false}
        buttonClose={false}
      >
        <BurgerMenu
          closeMobileMenu={closeMobileMenu}
          offers={offers}
          onOpenPopup={handleMobileMenuPopup}
        />
      </Popup> */}

      <Popup
        show={isAuthFormOpen}
        onClose={closeAuthForm}
        variant='small'
        panelClassNames={styles.SiteHeader__background_grey}
      >
        <AuthForm onClose={closeAuthForm} />
      </Popup>

      <Popup
        show={isOffersPopupOpen}
        onClose={closeOffersPopup}
        variant='medium'
      >
        <OffersList offers={offers} />
      </Popup>
    </>
  );
};

export default SiteHeader;
