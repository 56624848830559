import React, { forwardRef } from 'react';
// import { useTranslation } from 'react-i18next';

import usePopup from '../../../../../hooks/use-popup';

import Popup from '../../Popup/Popup';
import OffersList from '../../OffersList';
import PageSectionHeader from '../../PageSectionHeader/PageSectionHeader';

import OffersSlider from '../../OffersSlider';

import * as styles from './OffersSection.module.scss';
import Action from '../../Action/Action';

const OffersSection = forwardRef(({ offers }, ref) => {
  // const { t } = useTranslation(['mainPage'])

  const [isOffersPopupOpen, openOffersPopup, closeOffersPopup] = usePopup();

  return (
    <>
      <section className={styles.OffersSection}>
        <div
          ref={ref}
          style={{
            position: 'absolute',
            top: 'calc(-1.5 * var(--Header__height))'
          }}
        />

        <div className={styles.OffersSection__header}>
          <PageSectionHeader>
            {/* {t('OffersSection.header')} */}
            Туры
          </PageSectionHeader>
          <Action
            onClick={openOffersPopup}
            className={styles.OffersSection__allOffersLink}
            themes={['Link']}
          >
            {/* {t('OffersSection.allOffersLink')} */}
            <div className={styles.OffersSection__allOffersText}>СМОТРЕТЬ ВСЕ</div>

          </Action>
        </div>

        <OffersSlider
          sliderClassName={styles.OffersSection__offersSlider}
          offers={offers}
        />
      </section>

      <Popup
        show={isOffersPopupOpen}
        onClose={closeOffersPopup}
        variant='medium'
        withHeader={false}
      >
        <OffersList offers={offers} />
      </Popup>
    </>
  );
});

export default OffersSection;
