import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Feature.module.scss';

const Feature = ({ feature }) => {
  const featureHtml = feature.content
    .replace(/\[/g, '<span>')
    .replace(/\]/g, '</span>');

  return (
    <div className={styles.Feature}>
      {/* <div className={styles.Feature__icon} /> */}
      <div
        className={styles.Feature__content}
        dangerouslySetInnerHTML={{ __html: featureHtml }}
      />
    </div>
  );
};

Feature.propTypes = {
  feature: PropTypes.shape({
    id: PropTypes.string,
    content: PropTypes.string
  }).isRequired
};

export default Feature;
