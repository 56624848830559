export default function sortObject(obj) {
  const arr = [];
  let prop;

  for (prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      arr.push({
        'key': prop,
        'value': obj[prop]
      });
    }
  }

  arr.sort((a, b) => a.value.position - b.value.position);

  return arr;
}
