/* React Components */
import ReactRailsUJS from 'react_ujs';
import MainPage from './javascript/components/MainPage/MainPage';
import AccountPage from './javascript/components/AccountPage/AccountPage';
import OrdersPage from './javascript/components/OrdersPage/OrdersPage';
import OrderSection from './javascript/components/OrdersPage/OrderSection';
import ReferalOrdersPage from './javascript/components/OrdersPage/ReferalOrdersPage';

ReactRailsUJS.detectEvents();
window.MainPage = MainPage;
window.AccountPage = AccountPage;
window.OrdersPage = OrdersPage;
window.OrderSection = OrderSection;
window.ReferalOrdersPage = ReferalOrdersPage;
