/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';
import Portal from '../Portal/Portal';

import * as styles from './Popup.module.scss';

import Close from '../../../../../icons/close-new.svg';

const Popup = ({
  children,
  show,
  onClose,
  panelClassNames,
  footer,
  header,
  headerColor,
  variant,
  withHeader,
  contentPadding,
  buttonClose,
  outsideClose,
  escapeClose
}) => {
  const [render, _setRender] = useState(show);
  const renderRef = useRef(show);
  // const popupRef = useRef<HTMLDivElement>();

  const setRender = (value) => {
    renderRef.current = value;
    _setRender(value);
  };

  useEffect(() => {
    if (show) {
      setRender(true);
      // disable background scroll
      document.body.style.overflow = 'hidden';
    }
  }, [show]);

  const handleEsc = () => {
    // !! avoind onClose on all Popup components in tree.
    if (renderRef.current) {
      onClose();
      // enable background scroll
      document.body.style.overflow = '';
    }
  };

  useEffect(() => {
    const onKeydown = (e) => {
      if (e.key === 'Escape' && escapeClose) handleEsc();
    };
    window.addEventListener('keydown', onKeydown);

    return () => {
      window.removeEventListener('keydown', onKeydown);
      // enable background scroll
      document.body.style.overflow = '';
    };
  }, []);

  const onAnimationEnd = () => {
    if (!show) {
      setRender(false);
      // enable background scroll
      document.body.style.overflow = '';
    }
  };

  const handleOverlayClick = () => {
    if (outsideClose) {
      onClose();
      // enable background scroll
      document.body.style.overflow = '';
    }
  };

  const handleOnClose = () => {
    onClose();
    // enable background scroll
    document.body.style.overflow = '';
  };

  if (!render) return null;

  return (
    <Portal>
      <div
        className={cn(
          styles.Popup,
          styles[`Popup_show_${show}`],
          { [styles[`Popup_${variant}`]]: variant },
          { [styles.Popup_withFooter]: footer },
          { [styles.Popup_withHeader]: withHeader },
          { [styles.Popup_contentPadding]: contentPadding }
        )}
      >
        <div className={styles.Popup__overlay} onClick={handleOverlayClick} />

        <div
          className={cn(
            styles.Popup__panel,
            panelClassNames,
            { [styles[`Popup_content_${headerColor}`]]: headerColor },
          )}
          onAnimationEnd={onAnimationEnd}
        >
          {buttonClose && (
            <button
              type='button'
              className={styles.Popup__close}
              onClick={handleOnClose}
            >
              <IconSvg icon={Close} className={styles.Popup__iconClose} />
            </button>
          )}

          {withHeader && (
            <div
              className={cn(
                styles.Popup__header,
                { [styles[`Popup_header_${headerColor}`]]: headerColor }
              )}
            >
              {!headerColor && header}
              {headerColor && (
                <div dangerouslySetInnerHTML={{ __html: header }} />
              )}
            </div>
          )}

          <div
            className={cn(
              styles.Popup__content,
              { [styles.Popup_content_border]: headerColor }
          )}
          >
            {children}
          </div>

          {footer && <div className={styles.Popup__footer}>{footer}</div>}
        </div>
      </div>
    </Portal>
  );
};

Popup.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  panelClassNames: PropTypes.string,
  footer: PropTypes.element,
  header: PropTypes.element,
  headerColor: PropTypes.element,
  variant: PropTypes.string,
  withHeader: PropTypes.bool,
  contentPadding: PropTypes.bool,
  buttonClose: PropTypes.bool,
  outsideClose: PropTypes.bool,
  escapeClose: PropTypes.bool
};

Popup.defaultProps = {
  children: null,
  show: false,
  panelClassNames: '',
  footer: null,
  header: null,
  headerColor: '',
  variant: null,
  withHeader: true,
  contentPadding: true,
  buttonClose: true,
  outsideClose: true,
  escapeClose: true
};

export default Popup;
