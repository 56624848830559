import { ru } from 'date-fns/locale';
import isDate from 'date-fns/isDate';
import parse from 'date-fns/parse';
import { zonedTimeToUtc } from 'date-fns-tz';

export const dateFnsLocales = { ru };

const dateToMoscowZone = date => zonedTimeToUtc(date, 'Europe/Moscow');
const dateToUtc = date => zonedTimeToUtc(date.getTime(), 'UTC');

export const currentMoscowDate = dateToMoscowZone(new Date());

export const defaultDateFormat = (_, originalValue) => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'dd.MM.yyyy', currentMoscowDate, { locale: dateFnsLocales.ru });

  // console.log('parsedDate =>', parsedDate);

  return dateToUtc(parsedDate);
};
