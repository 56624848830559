const currencyOptions = {
  'РУБ': {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
    currencyDisplay: 'symbol'
  },
  'RUB': {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
    currencyDisplay: 'symbol'
  },
  '₽': {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
    currencyDisplay: 'symbol'
  },
  'USD': {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    currencyDisplay: 'symbol'
  },
  'EUR': {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    currencyDisplay: 'symbol'
  }
};

const numberToMoney = (
  amount,
  currency = 'RUB',
  locale = 'ru-RU'
) => new Intl.NumberFormat(locale, { ...currencyOptions[currency] }).format(amount);

const numberToMoneyWithoutCurrency = (
  amount,
  currency = 'RUB',
  locale = 'ru-RU'
) => {
  const money = numberToMoney(amount, currency, locale);

  return money.split('').slice(0, -2).join('');
};

export {
  numberToMoney,
  numberToMoneyWithoutCurrency
};
