import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Logo from '../../../../../../icons/logo-new.svg';
import LogoSectionImage from '../../../../../../images/logo-section-image.jpg';

import IconSvg from '../../../../../common/javascript/components/IconSvg/IconSvg';

import * as styles from './LogoSection.module.scss';

const LogoSection = forwardRef((props, ref) => {
  const {
    bubbleText
  } = props;

  return (
    <section className={styles.LogoSection}>
      <div
        ref={ref}
        style={{
          position: 'absolute',
          top: 'calc(-1.5 * var(--Header__height))'
        }}
      />
      <IconSvg icon={Logo} />
      <div className={styles.LogoSection__contentWrap}>
        <div className={styles.LogoSection__content}>
          <div className={styles.LogoSection__imageWrap}>
            <img
              alt='Beautiful picture'
              src={LogoSectionImage}
              className={styles.LogoSection__image}
            />
          </div>
          <div className={styles.LogoSection__bubbleSpeech}>
            {bubbleText}
          </div>
        </div>
      </div>
    </section>
  );
});

LogoSection.propTypes = {
  bubbleText: PropTypes.string
};

LogoSection.defaultProps = {
  bubbleText: 'В 2019 ГОДУ НА TINKOFF ROSAFEST ВЫПАЛО 2 МЕТРА СВЕЖЕГО СНЕГА'
};

export default LogoSection;
