import * as React from 'react';
import PropTypes from 'prop-types';

import Action from '../../Action/Action';

import authSteps from '../form-steps';

import * as styles from './PhoneInputControls.module.scss';

const PhoneInputControls = ({
  isPhoneValid,
  step,
  onChangePhoneRequest
}) => {
  const isPhoneStep = step === authSteps.PHONE;
  const isPasswordStep = step === authSteps.PASSWORD;

  const nextButton = (
    <Action
      type='submit'
      themes={['Button', 'ButtonMedium', 'ButtonCommon', 'ButtonWarning']}
      disabled={!isPhoneValid}
    >
      Далее
    </Action>
  );

  const changePhoneLink = (
    <Action
      type='button'
      className={styles.Controls__changePhone}
      onClick={onChangePhoneRequest}
      themes={['Link']}
    >
      Изменить
    </Action>
  );

  return (
    <div className={styles.Controls}>
      {isPhoneStep && nextButton}
      {isPasswordStep && changePhoneLink}
    </div>
  );
};

PhoneInputControls.propTypes = {
  isPhoneValid: PropTypes.bool.isRequired,
  step: PropTypes.string.isRequired,
  onChangePhoneRequest: PropTypes.func.isRequired
};

export default PhoneInputControls;
