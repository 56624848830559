/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';
import CheckOnColorIcon from '../../../../../icons/check-on-color.svg';
import CheckOnColor2Icon from '../../../../../icons/check-on-color2.svg';
import RadioOnColorIcon from '../../../../../icons/radio-on-color.svg';

import * as styles from './Checkbox.module.scss';

const icons = {
  checkbox2: CheckOnColorIcon,
  checkbox: CheckOnColor2Icon,
  radio: RadioOnColorIcon
};

const Checkbox = ({
  id,
  label,
  type,
  likeButton,
  className,
  ...props
}) => {
  const Icon = icons[type];

  return (
    <label
      className={cn(
        styles.Checkbox,
        { [styles.Checkbox_likeButton]: likeButton },
        { [className]: className }
      )}
      htmlFor={id}
    >
      <input
        type={type}
        id={id}
        {...props}
      />

      <div className={styles.Checkbox__control}>
        <IconSvg icon={Icon} />
        <span className={cn(
          styles.Checkbox__controlUnchecked,
          styles[`Checkbox__controlUnchecked_${type}`],
        )}
        />
      </div>

      <div className={styles.Checkbox__label}>{label}</div>

      {
        likeButton && <div className={styles.Checkbox__background} />
      }
    </label>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  likeButton: PropTypes.bool,
  className: PropTypes.string
};

Checkbox.defaultProps = {
  type: 'checkbox',
  likeButton: false,
  className: ''
};

export default Checkbox;
