import React from 'react';

export const SpotwayLogoDefaultGradient = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    focusable='false'
    style={{ width: 0, height: 0, position: 'absolute', visibility: 'hidden' }}
  >
    <defs>
      <linearGradient id='SpotwayLogoDefaultGradient' x1='35.209' y1='6.152' x2='35.209' y2='41.267' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#462B7F' />
        <stop offset='1' stopColor='#8D45A4' />
      </linearGradient>
    </defs>
  </svg>
);

export const RecallGradient = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    focusable='false'
    style={{ width: 0, height: 0, position: 'absolute', visibility: 'hidden' }}
  >
    <defs>
      <linearGradient id='RecallGradient' x1='15' y1='0' x2='15' y2='30' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#462B7F' />
        <stop offset='1' stopColor='#883C97' />
      </linearGradient>
    </defs>
  </svg>
);

export const InstagramGradient = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    focusable='false'
    style={{ width: 0, height: 0, position: 'absolute', visibility: 'hidden' }}
  >
    <defs>
      <linearGradient id='InstagramGradient' x1='15.75' y1='0' x2='15.75' y2='28' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#614FC0' />
        <stop offset='0.130208' stopColor='#9A34AF' />
        <stop offset='0.510417' stopColor='#DC4C55' />
        <stop offset='1' stopColor='#EFBD5F' />
      </linearGradient>
    </defs>
  </svg>
);

export const MirGradient = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    focusable='false'
    style={{ width: 0, height: 0, position: 'absolute', visibility: 'hidden' }}
  >
    <defs>
      <linearGradient x1='100%' y1='62.9043048%' x2='0%' y2='62.9043048%' id='MirGradient'>
        <stop stopColor='#1F5CD7' offset='0%' />
        <stop stopColor='#02AEFF' offset='100%' />
      </linearGradient>
    </defs>
  </svg>
);

export const VisaGradient = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    focusable='false'
    style={{ width: 0, height: 0, position: 'absolute', visibility: 'hidden' }}
  >
    <defs>
      <linearGradient id='VisaGradient' x1='21.1' y1='5.11629' x2='46.2035' y2='5.11629' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#20225F' />
        <stop offset='0.2' stopColor='#1A1F61' />
        <stop offset='0.410233' stopColor='#172272' />
        <stop offset='0.595197' stopColor='#152682' />
        <stop offset='0.802083' stopColor='#12288E' />
        <stop offset='1' stopColor='#0E2C9A' />
      </linearGradient>
    </defs>
  </svg>
);
