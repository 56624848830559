import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import * as styles from './FormElementLabel.module.scss';

const FormElementLabel = ({ children, className }) => {
  if (children === undefined) return null;

  return (
    <div
      className={cn(
        styles.FormElementLabel,
        { [className]: className }
      )}
    >
      {children}
    </div>
  );
};

FormElementLabel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

FormElementLabel.defaultProps = {
  className: ''
};

export default FormElementLabel;
