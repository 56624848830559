import React from 'react';
import PropTypes from 'prop-types';

import Action from '../../../../components/Action';
import IconSvg from '../../../../../../common/javascript/components/IconSvg/IconSvg';

import MapIcon from '../../../../../../../icons/map.svg';
import TimeIcon from '../../../../../../../icons/time.svg';

import * as styles from './HotelInfo.module.scss';

const HotelInfo = ({ hotel }) => {
  const hotelLink = hotel?.latitude ? `https://yandex.ru/maps/?pt=${hotel.latitude},${hotel.longitude}&z=17` : `https://yandex.ru/maps/?text=${hotel?.name}`;

  const checkInDepartureTime = () => {
    let text = '';
    if (hotel.check_in) text = `заезд после ${hotel.check_in}`;
    if (hotel.check_in && hotel.departure) text += ' / ';
    if (hotel.departure) text += `выезд до ${hotel.departure}`;

    return text;
  };

  return (
    <div className={styles.HotelInfo__wrap}>
      <div className={styles.HotelInfo__title}>
        <div className={styles.HotelInfo__name}>
          {hotel?.name}
        </div>
        <Action
          href={hotelLink}
          className={styles.HotelInfo__link}
          target='_blank'
        >
          <IconSvg icon={MapIcon} className={styles.HotelInfo__icon} />
          <div className={styles.HotelInfo__text}>
            на карте
          </div>
        </Action>
      </div>
      <div className={styles.HotelInfo__badgeWrap}>
        {hotel.interestCategoriesText?.map(cat => (
          <Action
            themes={['Button', 'ButtonSmall', 'ButtonBadge']}
            onClick={null}
          >
            {cat}
          </Action>
        ))}
      </div>
      <div
        className={styles.HotelInfo__timeWrap}
      >
        {(hotel.check_in || hotel.departure) && (
          <>
            <IconSvg icon={TimeIcon} className={styles.HotelInfo__icon} />
            <div className={styles.HotelInfo__text}>
              {checkInDepartureTime()}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

HotelInfo.propTypes = {
  hotel: PropTypes.shape({
    name: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }).isRequired
};

export default HotelInfo;
