import * as React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import * as styles from './MenuItems.module.scss';

const MenuItem = ({ children, isActive }) => {
  return (
    <li className={cn(styles.MenuItem, { [styles.MenuItem_active]: isActive })}>
      {children}
    </li>
  );
};

MenuItem.propTypes = {
  children: PropTypes.element.isRequired,
  isActive: PropTypes.bool
};

MenuItem.defaultProps = {
  isActive: false
};

export default MenuItem;
