import React from 'react';
import PropTypes from 'prop-types';

import ManagerContactComponent from '../ManagerContact';

import * as styles from './BecomePartner.module.scss';

const BecomePartner = ({ contact }) => (
  <div className={styles.BecomePartner}>
    <div className={styles.BecomePartner__desc}>
      {contact.hint && (
        <div className={styles.BecomePartner__content} dangerouslySetInnerHTML={{ __html: contact.hint }} />
      )}
      {!contact.hint && (
        <>
          <div>
            Отвечаем за крупнейшие ивенты индустрии action спорта и активного отдыха.
          </div>
          <div>
            Ежегодный зимний фестиваль Розафест на курорте Роза Хутор. Сезонные интеграции в горнолыжные курорты, сёрф, кайт, вейк станции, модные пляжи Москвы и Юга России с медиаохватом 200 миллионов в год. Более 100 эксклюзивных кейсов для 30 брендов по всему миру. До встречи на море и в горах!
          </div>
        </>
      )}
    </div>
    <div className={styles.BecomePartner__contact}>
      <ManagerContactComponent contact={contact} />
    </div>
  </div>
);

BecomePartner.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    hint: PropTypes.string
  }).isRequired
};

export default BecomePartner;
