function envResult(e) {
  // console.log('ReachGoal', e);
}


export default function ReachGoal(target, params) {
  if (typeof (ym) === 'function') {
    try {
      console.log('ReachGoal', `target => ${target}`, `params => ${params}`);

      ym(26037918, 'reachGoal', target, params, envResult);
    } catch (err) {
      console.log(err);
    }
  } else {
    // console.log('not func ym');
  }
}
