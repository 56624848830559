import React from 'react';
import PropTypes from 'prop-types';

import Slider from '../../Slider/Slider';
import VideosBlock from '../../VideosBlock';

import * as styles from './MediaSection.module.scss';

const MediaSection = ({ contents }) => (
  <>
    <div className={styles.MediaSection__slider}>
      <Slider contents={contents} />
    </div>
    <div className={styles.MediaSection__videoBlock}>
      <VideosBlock contents={contents} />
    </div>
  </>
);

MediaSection.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string
  })).isRequired
};

export default MediaSection;
