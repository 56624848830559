import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import TbankIdIcon from '../../../../../../icons/t_id.svg';
// import SnowflakeIcon from '../../../../../../icons/SW.svg';

import * as styles from './TbankButton.module.scss';
import IconSvg from '../../../../../common/javascript/components/IconSvg/IconSvg';
import Action from '../../Action';

const TbankButton = ({ className, onClick }) => (
  <div
    className={
      cn(
        styles.TbankButton,
        { [className]: className }
      )
    }
  >
    <Action
      className={styles.TbankButton}
      onClick={() => onClick()}
      themes={['Button', 'Fullwidth']}
    >
      <div className={styles.TbankButton__info}>Войти с</div>
      <IconSvg icon={TbankIdIcon} className={styles.TbankButton__logo} />
      {/* <IconSvg icon={SnowflakeIcon} className={styles.TbankButton__avatar} /> */}
    </Action>
  </div>
);

TbankButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

TbankButton.defaultProps = {
  className: ''
};

export default TbankButton;
