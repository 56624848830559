import React, { useContext } from 'react';

import cn from 'classnames';

import { MenuItems } from '../../MenuItems';
import Action from '../../Action';
import { OrderContext } from '../../../context/OrderContext';
import Popup from '../../Popup';
import usePopup from '../../../../../hooks/use-popup';
import PaymentForm from '../PaymentForm';

import * as styles from './Sidebar.module.scss';
import PaidForm from '../PaidForm';
import { numberToMoney } from '../../../../../helpers/currency';
import PromoCodeForm from '../PromoCodeForm/PromoCodeForm';
import { ReachGoal } from '../../../YandexMetrika';

const Sidebar = () => {
  const { order, isOrderPaid } = useContext(OrderContext);
  const [isPaymentOpened, openPayment, closePayment] = usePopup();
  const [isPaidOpened, openPaid, closePaid] = usePopup();
  const [isPromoOpened, openPromo, closePromo] = usePopup();

  const currentNotPaid = (order?.total_cost || 0) - (order?.amount_paid || 0);

  const handlePayment = () => {
    if (order.click_btn_pay_target) ReachGoal(order.click_btn_pay_target, { offer: order.offer_name });
    openPayment();
  };

  const handlePaid = () => {
    openPaid();
  };

  const handlePromo = () => {
    openPromo();
  };

  const handlePromoClose = () => {
    closePromo();
  };

  return (
    <>
      <div className={styles.Sidebar}>
        <MenuItems vertical />

        <div className={styles.Sidebar__totalCost}>
          {(!isOrderPaid && order.status_cd < 2) && (
            <>
              <div className={cn(styles.Sidebar__row, styles.Sidebar__title)}>
                К оплате
              </div>
              <div className={cn(styles.Sidebar__row, styles.Sidebar__notPaid)}>
                {numberToMoney(currentNotPaid)}
              </div>
              <div className={cn(styles.Sidebar__row, styles.Sidebar__actionPay)}>
                <Action
                  onClick={order.status_cd !== 1 ? null : handlePayment}
                  themes={order.status_cd !== 1 ? ['Button', 'Fullwidth', 'ButtonSecondary'] : ['Button', 'Fullwidth']}
                >
                  <span>Оплатить</span>
                </Action>
              </div>
            </>
          )}
          {/* <div className={cn(styles.Sidebar__row, styles.Sidebar__actionPaid)}>
            <Action
              onClick={handlePaid}
              themes={['Link']}
            >
              {order.amount_paid > 0 && (
                `оплачено ${numberToMoney(order.amount_paid)}`
              )}
              {order.amount_paid === 0 && (
                'Платите частями'
              )}
            </Action>
          </div> */}
          {order.has_promo && !order.used_promo_code && (
            <div className={cn(styles.Sidebar__row, styles.Sidebar__actionPaid)}>
              <Action
                onClick={handlePromo}
                themes={['Link']}
              >
                промокод
              </Action>
            </div>
          )}
        </div>
      </div>

      <Popup
        show={isPaymentOpened}
        onClose={closePayment}
        escapeClose={false}
        outsideClose={false}
        variant='small'
      >
        <PaymentForm openPaid={handlePaid} closePaymentForm={closePayment} />
      </Popup>

      <Popup
        show={isPaidOpened}
        onClose={closePaid}
        variant='small'
      >
        <PaidForm />
      </Popup>

      <Popup
        show={isPromoOpened}
        onClose={closePromo}
        variant='small'
      >
        <PromoCodeForm onClose={handlePromoClose} />
      </Popup>
    </>
  );
};

export default Sidebar;
