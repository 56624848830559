import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { haveBanner, onCloseBanner } from '../../../../services/personal';

import styles from './Banner.module.scss';

import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';

import Close from '../../../../../icons/close-new.svg';

const PERONAL_SOCIAL_BANNER = 'peronalSocialBanner';

const Banner = ({
  content,
  className
}) => {
  const [banner, setBanner] = useState(true);

  const handleBannerClose = (e) => {
    e.preventDefault();
    onCloseBanner(PERONAL_SOCIAL_BANNER);
    setBanner(true);
  };

  useEffect(() => {
    setBanner(haveBanner(PERONAL_SOCIAL_BANNER));
  }, []);

  const bannerEmail = () => {
    if (banner) {
      return '';
    }

    return (
      <div
        className={
          cn(className, styles.Banner,
            { [styles.Banner__shadow]: true },
            { [styles.Banner__padding]: true })
        }
      >
        <div className={styles.Banner__name}>
          {content}
        </div>
        <button
          type='button'
          className={styles.Banner__close}
          onClick={handleBannerClose}
        >
          <IconSvg icon={Close} />
        </button>
      </div>
    );
  };

  return bannerEmail();
};

Banner.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string
};

Banner.defaultProps = {
  content: 'Для сохранения скидки и истории заказов введи свой e-mail или авторизуйся через соцсети',
  className: ''
};

export default Banner;
