import React, { useState, useEffect } from 'react';

import BaseShellContainer from '../BaseShellContainer';
import ProfileTabs, { PROFILE_TABS } from '../ProfileTabs';
import Layout from '../Layout';
import NoOrders from './NoOrders';
import OrdersList from '../OrdersList';
import Checkbox from '../Checkbox';
import { AuthProvider } from '../../../../hooks/use-auth';
import { fetchBuildData } from '../../../../services/base';
import Loader from '../Loader';

// import * as styles from './OrdersPage.module.scss';

const OrdersPage = () => {
  const [contactsData, setContactsData] = useState([]);
  const [offersData, setOffersData] = useState([]);
  const [ordersData, setOrdersData] = useState(null);
  const [allOrders, setAllOrders] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBuildData((result) => {
      setContactsData(result.contacts);
      setOffersData(result.offers);
      setOrdersData(result.orders);
      setLoading(false);
    });
  }, []);

  const filterOrders = (orders) => {
    if (allOrders) return orders;

    return ordersData.filter(f => f.actual);
  };

  const onChoose = () => {
    setAllOrders(!allOrders);
  };

  return (
    <AuthProvider>
      <Loader view={loading} />
      <Layout withBackground contacts={contactsData.forTours} offers={offersData}>
        <BaseShellContainer>
          <ProfileTabs currentTab={PROFILE_TABS.orders} />
          {ordersData && ordersData.length > 0 && (
            <Checkbox
              id='actual-orders-id'
              name='actual-orders'
              label='Отмененные и прошлые заказы'
              onClick={onChoose}
              type='radio'
              checked={allOrders}
            />
          )}
          {ordersData && ordersData.length > 0 && <OrdersList orders={filterOrders(ordersData)} />}
          {ordersData && ordersData.length === 0 && <NoOrders offers={offersData} />}
        </BaseShellContainer>
      </Layout>
    </AuthProvider>
  );
};

export default OrdersPage;
