import { useState } from 'react';

const usePopup = (initialIsOpen = false) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return [isOpen, openPopup, closePopup];
};

export default usePopup;
