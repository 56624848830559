import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as styles from './PromoCodeForm.module.scss';
import FormsRow from '../../FormsRow';
import TextInput from '../../TextInput';
import Action from '../../Action';
import { OrderContext } from '../../../context/OrderContext';

const FormSchema = Yup.object().shape({
  promoCode: Yup.string()
    .required('Введите промокод')
});

const PromoCodeForm = ({ onClose }) => {
  const { order, handleAddPromoCode } = useContext(OrderContext);
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors }
  } = useForm({
    mode: 'onChanges',
    reValidateMode: 'onChange',
    resolver: yupResolver(FormSchema)
  });

  const promoCode = watch('promoCode');

  const onSubmit = (data) => {
    const formData = { ...data, id: order.id };
    // console.log(formData);
    handleAddPromoCode(formData, (callback) => {
      // console.log('PromoCodeForm onSubmit => ', callback);
      if (callback.status !== 'error') onClose();

      if (callback.status === 'error') {
        setError('promoCode', { message: callback.error });
      }
    });
  };

  return (
    <div className={styles.PromoCodeForm}>
      <FormsRow>
        <TextInput
          labelText='ПРОМОКОД СО СКИДКОЙ'
          type='text'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('promoCode')}
          error={errors.promoCode?.message}
          value={promoCode}
        />
      </FormsRow>

      <FormsRow>
        <Action
          type='submit'
          themes={['Button', 'Fullwidth', 'ButtonCommon', 'ButtonWarning']}
          disabled={!errors}
          onClick={handleSubmit(onSubmit)}
        >
          Применить
        </Action>
      </FormsRow>
    </div>
  );
};

PromoCodeForm.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default PromoCodeForm;
