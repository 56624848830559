import React, { useState, useEffect } from 'react';

import ProfileTabs, { PROFILE_TABS } from '../ProfileTabs';
import Layout from '../Layout/Layout';
import BaseShellContainer from '../BaseShellContainer';
import Sections from './Sections';
import UserPresentation from './UserPresentation';
import { AuthProvider } from '../../../../hooks/use-auth';
import { fetchBuildData } from '../../../../services/base';

import * as styles from './AccountPage.module.scss';

const user = {
  name: 'ИМЯ',
  surname: 'ФАМИЛИЯ',
  discount: 5,
  nextDiscount: 10,
  ordersToNextDiscount: 7
};

const AccountPage = () => {
  const [contactsData, setContactsData] = useState([]);
  const [offersData, setOffersData] = useState([]);

  useEffect(() => {
    fetchBuildData((result) => {
      setContactsData(result.contacts);
      setOffersData(result.offers);
    });
  }, []);

  return (
    <AuthProvider>
      <Layout withBackground contacts={contactsData.forTours} offers={offersData}>
        <BaseShellContainer>
          <ProfileTabs currentTab={PROFILE_TABS.account} />
          <div className={styles.AccountPage}>
            <UserPresentation
              name={user?.name}
              surname={user?.surname}
              discount={user?.discount}
              nextDiscount={user?.nextDiscount}
              ordersToNextDiscount={user?.ordersToNextDiscount}
            />
            <Sections />
          </div>
        </BaseShellContainer>
      </Layout>
    </AuthProvider>
  );
};

export default AccountPage;
