import React, { useEffect, useState } from 'react';

import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';
import Action from '../Action/Action';
import PaymentChannel from '../PaymentChannel/PaymentChannel';

import registerOfToImage from '../../../../../icons/to-register-logo.svg';

import { fetchFooterData } from '../../../../services/base';

import * as styles from './SiteFooter.module.scss';

const SiteFooter = () => {
  const [documentsData, setDocumentsData] = useState([]);
  const [tourOperator, setTourOperator] = useState('');
  const [tourOperatorUrl, setTourOperatorUrl] = useState('');
  const [ratingBageUrl, setRatingBageUrl] = useState('');

  const actions = documentsData.map(action => (
    <li key={action.title}>
      <Action href={action.path} target='_blank'>{action.title}</Action>
    </li>
  ));

  useEffect(() => {
    fetchFooterData((result) => {
      setDocumentsData(result.documents);
      setTourOperator(result.tour_operator);
      setTourOperatorUrl(result.tour_operator_url);
      setRatingBageUrl(result.rating_bage_url);
    });
  }, []);

  return (
    <div className={styles.SiteFooter}>
      <div className={styles.SiteFooter__layout}>
        <div className={styles.SiteFooter__address}>
          <div className={styles.SiteFooter__sectionLabel}>Адрес</div>
          <div>
            г.&nbsp;Москва,&nbsp;Покровский&nbsp;бульвар,
            <br />
            д. 4/17, стр. 5
          </div>
        </div>

        <div className={styles.SiteFooter__forClients}>
          <div className={styles.SiteFooter__sectionLabel}>Клиентам</div>
          <ul className={styles.SiteFooter__sectionMenu}>
            {actions}
          </ul>
        </div>

        <div className={styles.SiteFooter__rating}>
          <iframe
            className={styles.SiteFooter__ratingBadge}
            title='Spotway rating badge'
            frameBorder='0'
            height='50'
            width='150'
            src={ratingBageUrl}
          />
        </div>

        <div className={styles.SiteFooter__registerOfTo}>
          <a
            href={tourOperatorUrl}
            target='_blank'
            rel='noopener noreferrer'
            className={styles.SiteFooter__registerOfToLink}
          >
            <IconSvg icon={registerOfToImage} className={styles.SiteFooter__registerOfToImage} />
            <div className={styles.SiteFooter__registerOfToText}>
              {`Мы в реестре туроператоров ${tourOperator}`}
              <br />
              ООО&nbsp;СпотВэй.ру
            </div>
          </a>
        </div>

        <div className={styles.SiteFooter__paymentOptions}>
          <PaymentChannel channel='sbp' />
          <PaymentChannel channel='mir' />
          <PaymentChannel channel='sberpay' />
          <PaymentChannel channel='visa' />
        </div>
      </div>
    </div>
  );
};

export default SiteFooter;
