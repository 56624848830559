export default function scrollPageTo(to, duration = 600, offset = 0) {
  const easeOutQuad = (t, b, c, d) => {
    t /= d;
    return -c * t * (t - 2) + b;
  };

  return new Promise((resolve, reject) => {
    const element = document.scrollingElement;

    if (typeof to === 'string') {
      to = document.querySelector(to) || reject();
    }
    if (typeof to !== 'number') {
      to = to.getBoundingClientRect().top + element.scrollTop;
    }
    let currentTime = 0;
    const
      start = element.scrollTop,
      change = to - start,
      increment = 20;
    const animateScroll = () => {
      currentTime += increment;
      let val = easeOutQuad(currentTime, start, change, duration);

      element.scrollTop = val - offset;

      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      } else {
        resolve();
      }
    };
    animateScroll();
  });
}
