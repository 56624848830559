import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import * as styles from './PageSectionHeader.module.scss';

const PageSectionHeader = ({
  children,
  tag,
  noBottomSpacing,
  topSpacing,
  toCenter
}) => {
  const Tag = tag;

  return (
    <div
      className={cn(styles.PageSectionHeader, {
        [styles.PageSectionHeader_noBottomSpacing]: noBottomSpacing,
        [styles.PageSectionHeader_topSpacing]: topSpacing,
        [styles.PageSectionHeader_toCenter]: toCenter
      })}
    >
      <Tag>{children}</Tag>
    </div>
  )
};

PageSectionHeader.propTypes = {
  children: PropTypes.string.isRequired,
  tag: PropTypes.string,
  noBottomSpacing: PropTypes.bool,
  topSpacing: PropTypes.bool,
  toCenter: PropTypes.bool
};

PageSectionHeader.defaultProps = {
  tag: 'h2',
  noBottomSpacing: false,
  topSpacing: false,
  toCenter: false
};

export default PageSectionHeader;
