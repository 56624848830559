import React, { useContext, useState } from 'react';

import { OrderContext } from '../../../../context/OrderContext';
import Action from '../../../Action';
import SubOrder from './SubOrder';

import Popup from '../../../Popup';
import usePopup from '../../../../../../hooks/use-popup';

import * as styles from './ServiceSelect.module.scss';

const ServiceSelect = () => {
  const {
    order,
    isLoading,
    allSubOrders
  } = useContext(OrderContext);
  const [isPopupOpened, openPopup, closePopup] = usePopup();

  const [description, setDescription] = useState('');
  const [blockText, setBlockText] = useState('');
  const [headerColor, setHeaderColor] = useState('');

  if (!order.id) return '';


  const handleShowDescription = (group) => {
    setDescription(group?.description);
    if (group.blockDate) {
      setBlockText(`Добавить и изменить услугу можно до <strong>${group.blockDate}</strong>`);
      setHeaderColor('purple');
    } else {
      setBlockText('');
      setHeaderColor('');
    }
    openPopup();
  };

  return (
    <>
      <ul className={styles.ServiceSelect}>
        {allSubOrders.map(item => (
          <li key={item?.group?.id}>
            <div className={styles.ServiceSelect__row}>
              <div className={styles.ServiceSelect__wrapHead}>
                <div>
                  {item?.group?.description && (
                    <Action
                      themes={['Link']}
                      onClick={() => handleShowDescription(item?.group)}
                    >
                      {item?.group?.name}
                    </Action>
                  )}
                  {!item?.group?.description && item?.group?.name}
                </div>
              </div>
              <div className={styles.ServiceSelect__wrapSubOrdersList}>
                {item.SubOrderPeopleSelect.map(product => (
                  <SubOrder key={`subOrder-${product.subOrderName}`} product={product} />
                ))}
              </div>
            </div>
          </li>
        ))}
      </ul>

      <>
        <Popup
          show={isPopupOpened}
          onClose={closePopup}
          variant='medium'
          withHeader={blockText || false}
          header={blockText}
          headerColor={headerColor}
        >
          <div className={styles.ServiceSelect__desc}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </Popup>
      </>
    </>
  );
};

ServiceSelect.propTypes = {

};

export default ServiceSelect;
