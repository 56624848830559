import React from 'react';
import PropTypes from 'prop-types';

import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import Action from '../Action';
import Popup from '../Popup';
import usePopup from '../../../../hooks/use-popup';

import * as styles from './VideosBlock.module.scss';

const VideosBlock = ({ contents }) => {
  const [isPopupOpened, openPopup, closePopup] = usePopup();
  const videoContents = contents?.filter(content => content.type === 'video');

  const videoItem = content => (
    <section className={styles.Slider}>
      <Swiper
        className={styles.Swiper}
        grabCursor
        slidesPerView='auto'
        modules={[FreeMode]}
      >
        <SwiperSlide className={styles.Swiper__slide}>
          <div className={styles.Swiper__slideInner}>
            <iframe
              src={content?.url}
              title={content?.title}
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );

  const contentItem = () => {
    const items = videoContents.map(content => videoItem(content));

    return (
      <div className={styles.VideosBlock__item}>
        {items}
      </div>
    );
  };

  const firstContentItem = () => videoItem(videoContents[0]);

  return (
    <>
      <div className={styles.VideosBlock}>
        {videoContents?.length > 1 && (
          <div className={styles.VideosBlock__row}>
            <Action
              onClick={openPopup}
              className={styles.VideosBlock__link}
              themes={['Link']}
            >
              смотреть все видео
            </Action>
          </div>
        )}
        {videoContents?.length > 0 && firstContentItem()}
      </div>

      <Popup
        show={isPopupOpened}
        onClose={closePopup}
        variant='medium'
      >
        {contentItem(videoContents)}
      </Popup>
    </>
  );
};

VideosBlock.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      html: PropTypes.string
    }).isRequired
  ).isRequired
};

export default VideosBlock;
