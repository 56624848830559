import * as React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Tabs.module.scss';

const Tabs = ({ children }) => {
  return (
    <ul className={styles.Tabs}>
      {children}
    </ul>
  );
};

Tabs.propTypes = {
  children: PropTypes.element.isRequired
};

export default Tabs;
