import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import SiteHeader from '../SiteHeader';
import SiteFooter from '../SiteFooter';
import {
  SpotwayLogoDefaultGradient,
  RecallGradient,
  InstagramGradient,
  MirGradient,
  VisaGradient
} from '../../../../common/javascript/components/LinerGradient';

import * as styles from './Layout.module.scss';

const Layout = ({
  children,
  withBackground
}) => {
  const scrollContainerRef = useRef(null);

  const getScrollContainer = () => scrollContainerRef.current;

  return (
    <div
      className={cn(styles.Layout, {
        [styles.Layout_withBackground]: withBackground
      })}
    >
      <SpotwayLogoDefaultGradient />
      <RecallGradient />
      <InstagramGradient />
      <MirGradient />
      <VisaGradient />
      <div className={styles.Layout__scrollContainer} ref={scrollContainerRef}>
        <SiteHeader
          getSiteScrollContainer={getScrollContainer}
        />
        {children}
        <SiteFooter />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  withBackground: PropTypes.bool
};

Layout.defaultProps = {
  withBackground: false
};

export default Layout;
