import * as React from 'react';
import PropTypes from 'prop-types';

import * as styles from './BaseShellContainer.module.scss';

const BaseShellContainer = ({ children }) => (
  <div className={styles.BaseShellContainer}>{children}</div>
);

BaseShellContainer.propTypes = {
  children: PropTypes.element.isRequired
};

export default BaseShellContainer;
