import headersWithToken from '../helpers/header';
import { isBrowser } from '../helpers/browser';

const URLS = {
  'personal': '/api/v2/users',
  'passport': '/api/v2/passports',
  'foreign_passport': '/api/v2/foreign_passports'
};

const CONTROLLER = {
  'personal': 'user',
  'passport': 'passport',
  'foreign_passport': 'foreign_passport'
};

const getCurrentUser = async (formId, callback) => {
  try {
    const response = await fetch(`/api/v2/users/personal.json?view=${formId}`, {
      method: 'GET',
      headers: headersWithToken()
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

const updateUser = async (formData, formId, callback) => {
  const data = {};

  data[CONTROLLER[formId]] = formData;

  console.log(data);

  try {
    const response = await fetch(URLS[formId], {
      method: 'PATCH',
      headers: headersWithToken(),
      body: JSON.stringify(data)
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

const updatePassword = async (formData, callback) => {
  const data = {
    'user': formData
  };

  try {
    const response = await fetch('/api/v2/users/update_password', {
      method: 'PATCH',
      headers: headersWithToken(),
      body: JSON.stringify(data)
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchCurrentUser = (formId, result) => {
  getCurrentUser(formId, (callback) => {
    result(callback);
  });
};

export const handleUpdateUser = (formData, formId, result) => {
  updateUser(formData, formId, (callback) => {
    result(callback);
  });
};

export const handleUpdatePassword = (formData, result) => {
  updatePassword(formData, (callback) => {
    result(callback);
  });
};
export const haveBanner = (bannerId) => {
  if (isBrowser && window.localStorage.getItem(bannerId)) return true;

  return false;
};

export const onCloseBanner = (bannerId) => {
  if (isBrowser) {
    window.localStorage.setItem(bannerId, '1');
  }
};

export const searchPone = async (phone, callback) => {
  const data = {
    query: phone
  };

  try {
    const response = await fetch('/admin/users/search', {
      method: 'POST',
      headers: headersWithToken(),
      body: JSON.stringify(data)
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};
