import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import FormsRow from '../../FormsRow';
import TextInput from '../../TextInput';
import PhoneTextInput from '../../PhoneTextInput';
import Action from '../../Action';

import { handleRecall, handleFetchRecallData } from '../../../../../services/recall';

import * as styles from './RecallForm.module.scss';
import Select from '../../Select';

const MIN_LENGTH = 2;
const MAX_LENGTH = 50;

export const CALLBACK_TYPE = {
  'changePeriod': 'changePeriod',
  'smsNotSend': 'smsNotSend',
  'others': 'others'
};

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .required('Введите Имя')
    .min(MIN_LENGTH, `Должно быть больше ${MIN_LENGTH} символов`)
    .max(MAX_LENGTH, `Должно быть меньше ${MAX_LENGTH} символов`),
  description: Yup.string()
    .nullable(),
  phone: Yup.string()
    .required('Введите номер телефона')
    .length(15, 'Неверный номер телефона')
});

const RecallForm = ({
  onClose,
  recallText,
  callBackType,
  recallPhone,
  recallName,
  recallDescription
}) => {
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onChanges',
    reValidateMode: 'onChange',
    resolver: yupResolver(FormSchema)
  });

  const [recallTxt, setRecalTxt] = useState(recallText);

  const name = watch('name');
  const description = watch('description');
  const service = watch('service');
  const phone = watch('phone');

  const options = ['Позвонить', 'Написать в Телеграм', 'Написать в Whatsapp'].map(option => (
    <option value={option}>{option}</option>
  ));

  const onSubmit = (data) => {
    let smsText = '';

    if (callBackType === CALLBACK_TYPE.smsNotSend) {
      smsText = `Не приходит смс ${recallName || name || ''} ${recallPhone || phone || ''} ${service || ''}`;
    }

    if (callBackType === CALLBACK_TYPE.changePeriod || callBackType === CALLBACK_TYPE.others) {
      smsText = `${description || ''} ${recallName || name} ${recallPhone || phone} ${service}`;
    }

    // console.log({ ...data, ...{ sms_text: smsText } });
    handleRecall({ ...data, ...{ sms_text: smsText } }, (callback) => {
      if (callback.status !== 'internal_server_error') onClose();
    });
  };

  useEffect(() => {
    trigger('name');

    if (callBackType === CALLBACK_TYPE.smsNotSend) {
      setValue('description', `Не приходит смс ${recallName || name || ''} ${recallPhone || phone || ''} ${service || ''}`);
    }

    if (recallPhone) {
      setValue('phone', recallPhone);
    }
    if (recallName) {
      setValue('name', recallName);
    }
    if (recallDescription) {
      setValue('description', recallDescription);
    }
    if (!recallText) {
      handleFetchRecallData((callback) => {
        setRecalTxt(callback.recall_text || 'Отвечаем с 10:00 до 20:00');
      });
    }
  }, [name, phone, service, trigger]);

  return (
    <form className={styles.RecallForm} onSubmit={handleSubmit(onSubmit)}>
      <FormsRow>
        <h3 className={styles.RecallForm__header}>
          Свяжитесь со мной
        </h3>

        <div className={styles.RecallForm__hint}>
          {recallTxt}
        </div>
      </FormsRow>

      <FormsRow>
        <TextInput
          labelText='Имя'
          type='text'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('name')}
          error={errors.name?.message}
          value={name}
          onClick={() => trigger('name')}
        />
      </FormsRow>

      <FormsRow>
        <TextInput
          labelText='ТЕМА/КОММЕНТАРИЙ'
          type='text'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('description')}
          error={errors.description?.message}
          value={description}
        />
      </FormsRow>

      <FormsRow>
        <Select
          labelText='Выбрать способ связи'
          name='service'
          {...register('service')}
          error={errors.service?.message}
          // value={dropDownField}
        >
          {options}
        </Select>
      </FormsRow>

      <FormsRow>
        <PhoneTextInput
          labelText='Номер телефона'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('phone')}
          error={errors.phone?.message}
          value={phone}
        />
      </FormsRow>

      <FormsRow>
        <Action
          type='submit'
          themes={['Button', 'Fullwidth', 'ButtonCommon', 'ButtonWarning']}
          disabled={!errors}
          onClick={handleSubmit(onSubmit)}
        >
          Отправить
        </Action>
      </FormsRow>
    </form>
  );
};

RecallForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  recallText: PropTypes.string.isRequired,
  callBackType: PropTypes.string,
  recallPhone: PropTypes.string,
  recallName: PropTypes.string,
  recallDescription: PropTypes.string
};

RecallForm.defaultProps = {
  recallPhone: '',
  recallName: '',
  recallDescription: '',
  callBackType: 'others'
};

export default RecallForm;
