import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import FormsRow from '../../FormsRow';
import PhoneTextInput from '../../PhoneTextInput';
import Action from '../../Action';
import { OrderContext } from '../../../context/OrderContext';

import * as styles from './InviteOnPhone.module.scss';
import useAuth from '../../../../../hooks/use-auth';

const FormSchema = Yup.object().shape({
  phone: Yup.string()
    .required('Введите номер телефона')
    .length(15, 'Неверный номер телефона')
});

const InviteOnPhone = ({ memberId, onClose, headerText, descriptionText }) => {
  const {
    order,
    handleInviteUser
  } = useContext(OrderContext);
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onChanges',
    reValidateMode: 'onChange',
    resolver: yupResolver(FormSchema)
  });

  const phone = watch('phone');

  const onSubmit = (data) => {
    const params = {
      pnr: order.pnr,
      invite_member_id: memberId,
      user_id: user.id,
      ...data
    };

    handleInviteUser(params, (callback) => {
      console.log(callback);
    });
    onClose();
  };

  return (
    <form className={styles.InviteOnPhone} onSubmit={handleSubmit(onSubmit)}>
      <FormsRow>
        <h3 className={styles.InviteOnPhone__header}>
          {headerText}
        </h3>

        <div className={styles.InviteOnPhone__content}>
          {descriptionText}
        </div>
      </FormsRow>

      <FormsRow>
        <PhoneTextInput
          labelText='Номер телефона'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('phone')}
          error={errors.phone?.message}
          value={phone}
        />
      </FormsRow>

      <FormsRow>
        <Action
          type='submit'
          themes={['Button', 'Fullwidth']}
          disabled={!errors}
          onClick={handleSubmit(onSubmit)}
        >
          Пригласить
        </Action>
      </FormsRow>
    </form>
  );
};

InviteOnPhone.propTypes = {
  memberId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  descriptionText: PropTypes.string
};

InviteOnPhone.defaultProps = {
  headerText: 'Пригласить участника',
  descriptionText: 'Даю общий доступ для редактирования заказа'
};

export default InviteOnPhone;
