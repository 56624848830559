import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import cn from 'classnames';

import { isMobileDeviceType } from '../../../../../helpers/browser';
import { OrderContext } from '../../../context/OrderContext';
import Action from '../../Action';
import Checkbox from '../../Checkbox';
import TextInput from '../../TextInput';

import {
  amountWithInternetAcquiring,
  internetAcquiringAmount,
  productAmountWithDiscountSbp
} from '../../../../../helpers/payment';
import Popup from '../../Popup';
import usePopup from '../../../../../hooks/use-popup';

import * as styles from './PaymentForm.module.scss';
import { paymentTbank, sbpOrder } from '../../../../../services/order';
import PageSectionHeader from '../../PageSectionHeader';
import SbpForm from '../SbpForm/SbpForm';
import IconSvg from '../../../../../common/javascript/components/IconSvg/IconSvg';
import { numberToMoneyWithoutCurrency } from '../../../../../helpers/currency';

import SbpIcon from '../../../../../../icons/sbp-btn-white.svg';
import SbpBlueIcon from '../../../../../../icons/sbp-btn-blue.svg';
// import MirIcon from '../../../../../../icons/mir-lg.svg';
// import MasterCardIcon from '../../../../../../icons/mastercard-2.svg';
// import VisaIcon from '../../../../../../icons/visa-10.svg';
import Loader from '../../Loader';
import SbpQr from '../SbpQr/SbpQr';
import useWs from '../../../../../hooks/use-ws';
import { NOTIFY_OF_PAY } from '../Sections/PaymentSchedule/PaymentSchedule';

const CHECKED_TYPE = {
  FULL: 'full_payment',
  OWN: 'payment'
};

const CHECKED_PAYMENT = {
  'visa': 'visa',
  'mir': 'mir',
  'sberpay': 'sberpay',
  'sbp': 'sbp'
};

const PaymentForm = ({ openPaid, closePaymentForm }) => {
  const { order, orderItems } = useContext(OrderContext);
  const mask = {
    numeral: true,
    numeralThousandsGroupStyle: 'none'
  };
  const {
    register,
    watch,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChanges',
    reValidateMode: 'onChange'
  });

  const [ready, msg, send] = useWs(order.id);
  const [isFullChecked, setIsFullChecked] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [choosePayment, setChoosePayment] = useState(CHECKED_PAYMENT.sbp);
  const [sbpQrLink, setSbpQrLink] = useState(process.env.T_BANK_SBP_LINK);
  const [sbpQrOrderId, setSbpQrOrderId] = useState('');
  const [QrTimeout, setQrTimeout] = useState(0);
  const currentNotPaid = (order?.total_cost || 0) - (order?.amount_paid || 0);
  const amount = watch('amount', currentNotPaid);
  // const currentPaymentSchedule = order.amount_from_payment_schedules?.filter(p => Date(p.date < currentMoscowDate));
  const [isPaymentOpened, openPayment, closePayment] = usePopup();
  const [isMobile, setIsMobile] = useState(isMobileDeviceType());
  const prevDiscountSbp = productAmountWithDiscountSbp(orderItems) || 0;

  const discountSbp = () => {
    if (isFullChecked) return productAmountWithDiscountSbp(orderItems) || 0;

    return 0;
  };

  const validateAmount = () => {
    console.log(amount);
    if (amount <= 0) {
      setError('amount', { message: 'Сумма должна быть больше 0' });

      return false;
    }

    if (choosePayment === CHECKED_PAYMENT.sbp && amount < 10) {
      setError('amount', { message: 'Минимальная сумма операции с помощью СБП составляет 10 руб' });

      return false;
    }

    if (amount > order.total_cost) {
      setError('amount', { message: `Сумма не может быть больше ${order.total_cost_to_s}` });

      return false;
    }

    return true;
  };

  const onChoose = (type) => {
    if (CHECKED_TYPE.FULL === type) {
      setIsFullChecked(true);
      setIsChecked(false);
      setValue('amount', currentNotPaid);
    }
    if (CHECKED_TYPE.OWN === type) {
      setIsFullChecked(false);
      setIsChecked(true);
      setValue('amount', 0);

      // if (currentPaymentSchedule?.length > 0) {
      //   setValue('amount', currentPaymentSchedule[0].sum);
      // } else {
      //   setValue('amount', order.total_cost);
      // }
    }
  };

  const handleChoosePayment = (type) => {
    console.log(type);
    setChoosePayment(CHECKED_PAYMENT[type]);
  };

  const handleClosePayment = () => {
    const data = {
      order_id: order.id,
      amount: amount - discountSbp(),
      discount: discountSbp(),
      sbp_order_id: sbpQrOrderId,
      cashback_mir: '0'
    };

    setQrTimeout(0);
    setIsMobile(isMobileDeviceType());
    closePayment();
    sbpOrder(order.sbp_payment_url.revocation, data, (result) => {
      console.log(result);
      setSbpQrLink('');
    });
  };

  const onSubmit = () => {
    if (validateAmount()) {
      if (choosePayment === CHECKED_PAYMENT.sbp) {
        const data = {
          order_id: order.id,
          amount: amount - discountSbp(),
          discount: discountSbp()
        };
        console.log(choosePayment);
        setLoading(true);
        sbpOrder(order.sbp_payment_url.creation, data, (result) => {
          console.log(result);
          setSbpQrOrderId(result.sbp.order_id);
          setSbpQrLink(result.sbp.order_form_url);
          setQrTimeout(parseInt(process.env.SBP_TIMER, 0));
          openPayment();
          setLoading(false);
        });
      }
      if (choosePayment === CHECKED_PAYMENT.mir) {
        const data = {
          order_id: order.id,
          amount: amountWithInternetAcquiring(amount, order.internet_acquiring),
          cashback_mir: '0',
          internet_acquiring: internetAcquiringAmount(amount, order.internet_acquiring)
        };
        setLoading(true);
        paymentTbank(order.payment_url, data, (result) => {
          const ret = result.result;
          console.log(ret);
          if (ret.errorCode || ret.status === 'error') {
            setError('amount', { message: ret.errorMessage || ret.error });
          } else if (ret.ErrorCode && ret.ErrorCode !== '0') {
            setError('amount', { message: `${ret.Message} ${ret.Details}` });
          } else {
            window.location = ret.formUrl || ret.PaymentURL;
          }
          setLoading(false);
        });
      }
    }
  };

  useEffect(() => {
    if (ready && NOTIFY_OF_PAY.SUCCESS === msg?.notify) {
      // closePayment();
      // closePaymentForm();
      // openPaid();
      if (msg?.SuccessURL) window.location = msg?.SuccessURL;
    }
  }, [msg]);

  return (
    <>
      <Loader view={loading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.PaymentForm}>
          <div
            role='presentation'
            className={cn(
              styles.PaymentForm__field,
              { [styles.PaymentForm__checked]: isFullChecked }
            )}
            onClick={() => onChoose(CHECKED_TYPE.FULL)}
          >
            <div className={styles.PaymentForm__row}>
              <Checkbox
                label='Полная оплата'
                id='id-payment-checkbox-full'
                name='payment-checkbox-full'
                type='radio'
                onChange={() => onChoose(CHECKED_TYPE.FULL)}
                checked={isFullChecked}
              />
              <div className={styles.PaymentForm__price}>
                <div className={styles.PaymentForm__cost}>{numberToMoneyWithoutCurrency(currentNotPaid)}</div>
                <div className={styles.PaymentForm__currency}>{order.currency}</div>
              </div>
            </div>
            <div
              className={styles.PaymentForm__desc}
            >
              {prevDiscountSbp === 0 && ('+1 к карме')}
              {prevDiscountSbp > 0 && (
                <div className={styles.PaymentForm__acquiring}>
                  <span>
                    <div className={styles.PaymentForm__costRed}>
                      {`скидка ${prevDiscountSbp} ${order.currency} через СБП`}
                    </div>
                  </span>
                  <div className={styles.PaymentForm__price}>
                    <div className={styles.PaymentForm__cost}>{numberToMoneyWithoutCurrency(currentNotPaid - prevDiscountSbp)}</div>
                    <div className={styles.PaymentForm__currency}>{order.currency}</div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            role='presentation'
            className={cn(
              styles.PaymentForm__field,
              { [styles.PaymentForm__checked]: isChecked }
            )}
            onClick={() => onChoose(CHECKED_TYPE.OWN)}
          >
            <div className={styles.PaymentForm__row}>
              <Checkbox
                label='Указать свою сумму'
                id='id-payment-checkbox-own'
                name='payment-checkbox-own'
                type='radio'
                onChange={() => onChoose(CHECKED_TYPE.OWN)}
                checked={isChecked}
              />
            </div>
            <div
              className={styles.PaymentForm__desc}
            >
              Платите любыми частями по графику
              <br />
              {order.amount_from_payment_schedules.map(ps => (
                <>
                  {`до ${ps.format.date}: `}
                  <strong>{ps.format.sum}</strong>
                  {order.currency}
                  <br />
                </>
              ))}
            </div>
            {isChecked && (
              <div className={styles.PaymentForm__amountField}>
                <TextInput
                  // placeholder='placeholder'
                  type='text'
                  autoComplete='one-time-code'
                  inputMode='numeric'
                  mask={mask}
                  {...register('amount')}
                  error={errors.amount?.message}
                  value={amount}
                  afterContent={
                    <div className={styles.PaymentForm__inputCurrency}>{order.currency}</div>
                  }
                />
              </div>
            )}
          </div>

          <div className={styles.PaymentForm__paymentOptions}>
            <PageSectionHeader tag='h3' topSpacing toCenter>
              {/* Выберите способ оплаты */}
            </PageSectionHeader>

            <div
              className={styles.PaymentForm__field}
            >
              <div className={styles.PaymentForm__header}>Система быстрых платежей</div>
              <div className={styles.PaymentForm__rowRight}>
                <div className={styles.PaymentForm__price}>
                  {(discountSbp() > 0 && amount > 0) && (
                    <>
                      <div className={styles.PaymentForm__discountCost}>{numberToMoneyWithoutCurrency(amount)}</div>
                      <div className={styles.PaymentForm__discountCurrency}>
                        {order.currency}
                        &nbsp;/
                      </div>
                      <div className={styles.PaymentForm__cost}>{numberToMoneyWithoutCurrency(amount - discountSbp())}</div>
                      <div className={styles.PaymentForm__currency}>{order.currency}</div>
                    </>
                  )}
                  {(discountSbp() === 0 && amount > 0) && (
                    <>
                      <div className={styles.PaymentForm__cost}>{numberToMoneyWithoutCurrency(amount)}</div>
                      <div className={styles.PaymentForm__currency}>{order.currency}</div>
                    </>
                  )}
                </div>
              </div>
              <Action
                className={styles.PaymentForm__spbButton}
                themes={['Button', 'Fullwidth']}
                type='submit'
                onClick={() => handleChoosePayment(CHECKED_PAYMENT.sbp)}
              >
                <span>Оплатить</span>
                &nbsp;
                &nbsp;
                <IconSvg icon={SbpIcon} />
              </Action>
              <div
                className={styles.PaymentForm__descAcquiring}
              >
                {discountSbp() > 0 && (
                  <div className={styles.PaymentForm__acquiring}>
                    <span>{`скидка ${discountSbp()} ${order.currency} при оплате через СБП`}</span>
                  </div>
                )}
              </div>
            </div>

            <div
              className={styles.PaymentForm__field}
            >
              <div className={styles.PaymentForm__header}>Оплата картой МИР, Visa, MC, Union</div>
              <div className={styles.PaymentForm__header}>И через T-PAY, SberPay и Яндекс</div>
              {/* <div className={styles.PaymentForm__header}>Банковская карта</div> */}
              {/* <div className={styles.PaymentForm__icons}>
                <IconSvg icon={MirIcon} className={styles.PaymentForm__mir} />
                <IconSvg icon={MasterCardIcon} className={styles.PaymentForm__mastercard} />
                <IconSvg icon={VisaIcon} className={styles.PaymentForm__visa} />
              </div> */}
              <div className={styles.PaymentForm__rowRight}>
                <div className={styles.PaymentForm__price}>
                  {amount > 0 && order.internet_acquiring > 0 && (
                    <>
                      <div className={styles.PaymentForm__discountCost}>{numberToMoneyWithoutCurrency(amount)}</div>
                      <div className={styles.PaymentForm__discountCurrency}>
                        {order.currency}
                        &nbsp;/
                      </div>
                    </>
                  )}
                  {amount > 0 && (
                    <>
                      <div className={styles.PaymentForm__cost}>
                        {numberToMoneyWithoutCurrency(
                          amountWithInternetAcquiring(amount, order.internet_acquiring)
                        )}
                      </div>
                      <div className={styles.PaymentForm__currency}>{order.currency}</div>
                    </>
                  )}
                </div>
              </div>
              <Action
                themes={['Button', 'Fullwidth']}
                type='submit'
                onClick={() => handleChoosePayment(CHECKED_PAYMENT.mir)}
              >
                <span>Оплатить</span>
              </Action>
              <div
                className={styles.PaymentForm__descAcquiring}
              >
                {order.internet_acquiring > 0 && (
                  <div className={styles.PaymentForm__acquiring}>
                    <span>{order.internet_acquiring_text}</span>
                    {amount > 0 && (
                      <span>
                        {`+${numberToMoneyWithoutCurrency(internetAcquiringAmount(amount, order.internet_acquiring))} ${order.currency}`}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>

      <Popup
        show={isPaymentOpened}
        onClose={handleClosePayment}
        escapeClose={false}
        outsideClose={false}
        variant='small'
        header={(
          <div className={styles.PaymentForm__headerIcon}>
            {!isMobile && (<span>QR-код для оплаты</span>)}
            <IconSvg icon={SbpBlueIcon} />
          </div>
        )}
      >
        {isMobile && (
          <SbpForm
            sbpQrLink={sbpQrLink}
            QrTimeout={QrTimeout}
            onClose={handleClosePayment}
            amount={
              amount - discountSbp()
            }
            currency={order.currency}
            onDesktop={() => setIsMobile(false)}
          />
        )}
        {!isMobile && (
          <SbpQr
            sbpQrLink={sbpQrLink}
            QrTimeout={QrTimeout}
            onClose={handleClosePayment}
            amount={
              amount - discountSbp()
            }
            currency={order.currency}
          />
        )}
      </Popup>
    </>
  );
};

PaymentForm.propTypes = {
  openPaid: PropTypes.func.isRequired,
  closePaymentForm: PropTypes.func.isRequired

};

export default PaymentForm;
