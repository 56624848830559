import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import FormsRow from '../../FormsRow';
import TextInput from '../../TextInput';
import DateTextInput from '../../DateTextInput';

import { nullableString, nullableDate } from '../../../../../helpers/yupValidation';
import { fetchCurrentUser } from '../../../../../services/personal';

// import * as styles from './PassportForm.module.scss';

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .transform(nullableString)
    .min(2, 'Должен быть больше одного символа')
    .max(50, 'Должен быть не больше 50 символов')
    .nullable(),
  // .required('Заполни ИМЯ'),
  surname: Yup.string()
    .trim()
    .transform(nullableString)
    .min(2, 'Должен быть больше одного символа')
    .max(50, 'Должен быть не больше 50 символов')
    .nullable(),
  // .required('Заполни ФАМИЛИЯ'),
  patronymic: Yup.string()
    .trim()
    .transform(nullableString)
    // .required('Заполни ОТЧЕСТВО')
    .min(2, 'Должен быть больше одного символа')
    .max(50, 'Должен быть не больше 50 символов')
    .nullable(),
  number: Yup.string()
    .trim()
    .transform(nullableString)
    .min(10, 'Должен быть 10 символов')
    .max(11, 'Должен быть 10 символов')
    .nullable()
    .typeError('Введи корректный номер XXXX XXXXXX'),
  // .required('Заполни СЕРИЯ И НОМЕР ПАСПОРТА'),
  // issuance_data: Yup.string()
  // .required('Заполни КЕМ ВЫДАН')
  //   .nullable()
  //   .min(10, 'Должен быть больше одного символа')
  //   .max(255, 'Должен быть не больше 255 символов'),
  issuance_date: Yup.date()
    .transform(nullableDate)
    .max(new Date(), 'Твой паспорт ещё не выдан')
    .min(new Date(1945, 1, 1), 'Ты слишком старый')
    .nullable()
    .typeError('Введи корректную дату ДД.ММ.ГГГГ')
    // .required('Заполни ДАТА ВЫДАЧИ')
  // registered_residence: Yup.string()
  //   // .required('Заполни ЗАРЕГИСТРИРОВАН(А) ПО АДРЕСУ')
  //   .nullable()
  //   .min(2, 'Должен быть больше одного символа')
  //   .max(50, 'Должен быть не больше 50 символов')
});

const PassportForm = ({ formId, onSubmit }) => {
  const {
    register,
    watch,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChanges',
    reValidateMode: 'onChange',
    resolver: yupResolver(FormSchema)
  });

  const number = watch('number');
  const issuanceDate = watch('issuance_date');

  const callbackErrors = (cerrors) => {
    console.log('PassportForm => ', cerrors);
    Object.entries(cerrors).forEach(([key, value]) => {
      setError(key, { message: value });
    });
  };

  useEffect(() => {
    fetchCurrentUser(formId, (result) => {
      setValue('surname', result.user.surname);
      setValue('name', result.user.name);
      setValue('patronymic', result.user.patronymic);
      setValue('number', result.user.number);
      setValue('issuance_date', result.user.issuance_date);
      // setValue('issuance_data', result.user.issuance_data);
      // setValue('registered_residence', result.user.registered_residence);
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(data => onSubmit(data, formId, cerrors => callbackErrors(cerrors)))} id={formId}>
      <FormsRow>
        <TextInput
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('surname')}
          error={errors.surname?.message}
          labelText='Фамилия'
          placeholder='Иванов'
        />
      </FormsRow>

      <FormsRow>
        <TextInput
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('name')}
          error={errors.name?.message}
          labelText='Имя'
          placeholder='Иван'
        />
      </FormsRow>

      <FormsRow>
        <TextInput
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('patronymic')}
          error={errors.patronymic?.message}
          labelText='Отчество'
          placeholder='Иванович'
        />
      </FormsRow>

      <FormsRow>
        <TextInput
          mask={{
            blocks: [4, 6],
            delimiters: [' '],
            numericOnly: true
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('number')}
          error={errors.number?.message}
          labelText='Серия и номер паспорта'
          placeholder='4605 231964'
          value={number}
        />
      </FormsRow>

      <FormsRow>
        <DateTextInput
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('issuance_date')}
          error={errors.issuance_date?.message}
          labelText='Дата выдачи'
          placeholder='ДД.ММ.ГГГГ'
          value={issuanceDate}
        />
      </FormsRow>

      {/* <FormsRow>
        <TextInput
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('issuance_data')}
          error={errors.issuance_data?.message}
          labelText='Кем выдан'
          placeholder='ОВД Хамовники'
        />
      </FormsRow> */}

      {/* <FormsRow>
        <TextInput
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('registered_residence')}
          error={errors.registered_residence?.message}
          labelText='Зарегистрирован(а) по адресу'
          placeholder='г. Москва, ул. Удальцова, д. 85'
        />
      </FormsRow> */}
    </form>
  );
};

PassportForm.propTypes = {
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default PassportForm;
