import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Action from '../../../../components/Action';
import IconSvg from '../../../../../../common/javascript/components/IconSvg/IconSvg';

import MealIcon from '../../../../../../../icons/facilities/meal.svg';
import NotMealIcon from '../../../../../../../icons/facilities/not-meal.svg';
import SquareIcon from '../../../../../../../icons/facilities/square.svg';
import TimeIcon from '../../../../../../../icons/time.svg';
import SpecialRoomIcon from '../../../../../../../icons/special_room1.svg';
import ServicesAddIcon from '../../../../../../../icons/services-add.svg';

import * as styles from './RoomInfo.module.scss';
import usePopup from '../../../../../../hooks/use-popup';
import Popup from '../../../../components/Popup';

const RoomInfo = ({ room, hotel, openHotelForm }) => {
  const [isFormOpen, openForm, closeForm] = usePopup();
  const [qaBlock, SetQaBlock] = useState(null);
  const [blockText, setBlockText] = useState('');
  const [headerColor, setHeaderColor] = useState('');
  const icon = (room.foodTypeCode === 0)
    ? NotMealIcon
    : MealIcon;

  const checkInDepartureTime = () => {
    let text = '';
    if (hotel.check_in) text = `заезд после ${hotel.check_in}`;
    if (hotel.check_in && hotel.departure) text += ' / ';
    if (hotel.departure) text += `выезд до ${hotel.departure}`;

    return text;
  };

  const handleClickQuestion = (block) => {
    console.log(block);
    SetQaBlock(block);
    if (block.blockDate) {
      setBlockText(`Добавить и изменить услугу можно до <strong>${block.blockDate}</strong>`);
      setHeaderColor('purple');
    } else {
      setBlockText('');
      setHeaderColor('');
    }
    openForm();
  };

  const blockItem = hotel.sub_offer_block?.map(block => (
    <li key={`blockItem_${block.position}`}>
      <Action
        className={styles.RoomInfo__html}
        to='/'
        onClick={() => handleClickQuestion(block)}
      >
        <div className={styles.RoomInfo__infoArea}>
          <IconSvg icon={ServicesAddIcon} className={styles.RoomInfo__icon} />
          <div
            className={styles.RoomInfo__faqLink}
            dangerouslySetInnerHTML={{ __html: block.question }}
          />
        </div>
      </Action>
    </li>
  ));

  return (
    <>
      <div className={styles.RoomInfo__wrap}>
        <div className={styles.RoomInfo__title}>
          <Action
            className={styles.RoomInfo__link}
            to='/'
            onClick={openHotelForm}
          >
            <div className={styles.RoomInfo__text}>
              {hotel.name}
            </div>
          </Action>
          <div className={styles.RoomInfo__name}>
            {room?.name}
          </div>
          <div className={styles.RoomInfo__badgeWrap}>
            {hotel.interestCategoriesText?.map(cat => (
              <Action
                themes={['Button', 'ButtonSmall', 'ButtonBadge']}
                onClick={null}
              >
                {cat}
              </Action>
            ))}
          </div>
          <div
            className={styles.RoomInfo__timeWrap}
          >
            {(hotel.check_in || hotel.departure) && (
              <>
                <IconSvg icon={TimeIcon} className={styles.RoomInfo__icon} />
                <div className={styles.RoomInfo__text}>
                  {checkInDepartureTime()}
                </div>
              </>
            )}
          </div>
          <div className={styles.RoomInfo__infoArea}>
            <IconSvg icon={SquareIcon} className={styles.RoomInfo__icon} />
            <div>
              {`${room.square} М`}
              <sup>2</sup>
            </div>
            {room.special && (
              <div className={styles.RoomInfo__infoArea}>
                <IconSvg icon={SpecialRoomIcon} className={styles.RoomInfo__icon} />
                <div>
                  Особый
                </div>
              </div>
            )}
          </div>
          <div className={styles.RoomInfo__infoArea}>
            <IconSvg icon={icon} className={styles.RoomInfo__icon} />
            <span>
              {room.foodType}
            </span>
          </div>
          {hotel.sub_offer_block?.length > 0 && (
            <>
              <div className={styles.RoomInfo__subOfferHeader}>
                <div>Доп.услуги</div>
                <span>Прокачайте номер после подтверждения бронирования</span>
              </div>
              <ul className={styles.RoomInfo__faqList}>
                {blockItem}
              </ul>
            </>
          )}
        </div>
      </div>
      <Popup
        show={isFormOpen}
        onClose={closeForm}
        variant='medium'
        withHeader={blockText || false}
        header={blockText}
        headerColor={headerColor}
      >
        <h3 className={styles.RoomInfo__header}>
          {qaBlock?.question}
        </h3>
        <div
          className={styles.RoomInfo__html}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: qaBlock?.answer }}

        />
      </Popup>
    </>
  );
};

RoomInfo.propTypes = {
  room: PropTypes.shape({
    name: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }).isRequired,
  hotel: PropTypes.shape({
    name: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }).isRequired,
  openHotelForm: PropTypes.func.isRequired
};

export default RoomInfo;
