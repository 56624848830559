import React from 'react';
import PropTypes from 'prop-types';

import PageSectionHeader from '../../PageSectionHeader/PageSectionHeader';
import Action from '../../Action/Action';
import Popup from '../../Popup/Popup';
import BecomePartner from '../../BecomePartner';

import usePopup from '../../../../../hooks/use-popup';

import * as styles from './PartnersSection.module.scss';

const partnerItem = partner => (
  <div key={`partner_${partner.name}`} className={styles.PartnersSection__partner}>
    <a target='_blank' href={partner.url} rel=' noopener noreferrer'>
      <img
        className={styles.PartnersSection__logo}
        src={partner.logoUrl}
        alt={partner.name}
      />
    </a>
  </div>
);

const PartnersSection = ({ partners, contact }) => {
  const [isPopupOpen, openPopup, closePopup] = usePopup();

  if (!partners) return '';

  return (
    <>
      <section className={styles.PartnersSection}>
        <PageSectionHeader>Партнеры</PageSectionHeader>
        <div className={styles.PartnersSection__wrap}>
          <div className={styles.PartnersSection__partnersGrid}>
            {partners.map(partner => partnerItem(partner))}
          </div>
          <div className={styles.PartnersSection__cta}>
            <Action themes={['Button', 'ButtonCommon', 'ButtonWarning']} onClick={openPopup}>
              Стать партнером
            </Action>
          </div>
        </div>
      </section>

      <Popup show={isPopupOpen} onClose={closePopup} variant='medium'>
        <BecomePartner contact={contact} />
      </Popup>
    </>
  );
};

PartnersSection.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    logoUrl: PropTypes.string,
    name: PropTypes.string
  })).isRequired,
  contact: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  })
};

PartnersSection.defaultProps = {
  contact: {
    phone: '',
    whatsapp: '',
    telegram: '',
    email: '',
    vkUrl: '',
    instagramUrl: ''
  }
};

export default PartnersSection;
