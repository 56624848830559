import React, { useContext } from 'react';

import { OrderContext } from '../../../context/OrderContext';

import * as styles from './PaidForm.module.scss';
import PageSectionHeader from '../../PageSectionHeader';

const PaidForm = () => {
  const { order } = useContext(OrderContext);
  const canViewPaid = order.amount_paid > 0;

  const paidAmountText = (
    order.payments_date_amount_text.map(ps => (
      <>
        {ps.date}
        &nbsp;-
        &nbsp;
        <strong>{ps.sum}</strong>
        {order.currency}
        <br />
      </>
    ))
  );

  return (
    <div className={styles.PaidForm}>
      <PageSectionHeader tag='h3' noBottomSpacing>
        График оплаты
      </PageSectionHeader>

      {order.amount_from_payment_schedules.map(ps => (
        <>
          {`до ${ps.format.date}: `}
          <strong>{ps.format.sum}</strong>
          {order.currency}
          <br />
        </>
      ))}

      <PageSectionHeader tag='h3' noBottomSpacing topSpacing>
        История операций
      </PageSectionHeader>

      {!canViewPaid && (
        'Нет платежей'
      )}

      {canViewPaid && paidAmountText}
    </div>
  );
};


PaidForm.propTypes = {

};


export default PaidForm;
