import headersWithToken from '../helpers/header';

const getOrderData = async (orderId, viewId, callback) => {
  try {
    const response = await fetch(`/api/v2/orders/${orderId}.json?view=${viewId}`, {
      method: 'GET'
    });

    const result = await response.json();

    if (response.ok) {
      callback(result.order);
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateMember = async (url, formData, memberId, callback) => {
  const data = {
    id: memberId,
    member: formData
  };

  // console.log(data);

  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: headersWithToken(),
      body: JSON.stringify(data)
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateMemberClarifications = async (url, formData, callback) => {
  const data = {
    clarification: formData
  };

  // console.log(data);

  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: headersWithToken(),
      body: JSON.stringify(data)
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

export const viewedHint = async (orderId, hintName, callback) => {
  const data = {
    hint: hintName
  };

  try {
    const response = await fetch(`/api/v2/orders/${orderId}/viewed_hint.json`, {
      method: 'POST',
      headers: headersWithToken(),
      body: JSON.stringify(data)
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateOrder = async (url, formData, callback) => {
  // console.log(formData);

  try {
    const response = await fetch(`/api/v2/${url}.json`, {
      method: 'PATCH',
      headers: headersWithToken(),
      body: JSON.stringify(formData)
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchOrderData = ((orderId, result) => {
  getOrderData(orderId, 'docs_members_items', (callback) => {
    result(callback);
  });
});

export const fetchSubOrderData = ((orderId, result) => {
  getOrderData(orderId, 'sub_orders', (callback) => {
    result(callback);
  });
});

export const fetchAllSubOrderData = ((orderId, result) => {
  getOrderData(orderId, 'all_sub_orders', (callback) => {
    result(callback);
  });
});

export const paymentSberbank = async (url, formData, callback) => {
  // console.log(formData);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headersWithToken(),
      body: JSON.stringify(formData)
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

export const paymentTbank = async (url, formData, callback) => {
  // console.log(formData);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headersWithToken(),
      body: JSON.stringify(formData)
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

export const sbpOrder = async (url, formData, callback) => {
  // console.log(formData);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headersWithToken(),
      body: JSON.stringify(formData)
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addPromoCode = async (formData, callback) => {
  const data = {
    'id': formData.id,
    'promo_code': formData.promoCode
  };

  try {
    const response = await fetch(`/api/v2/orders/${formData.id}/add_promo_code.json`, {
      method: 'POST',
      headers: headersWithToken(),
      body: JSON.stringify(data)
    });

    const result = await response.json();

    if (response.ok) {
      callback(result);
    } else {
      callback(result);
    }
  } catch (error) {
    console.error(error);
  }
};
