import React from 'react';
import PropTypes from 'prop-types';

import { QRCodeSVG } from 'qrcode.react';

import * as styles from './SbpQr.module.scss';
import FormsRow from '../../FormsRow';
import Timer from '../../AuthForm/Timer/Timer';
import { numberToMoneyWithoutCurrency } from '../../../../../helpers/currency';


const SbpQr = ({
  sbpQrLink,
  QrTimeout,
  onClose,
  amount,
  currency
}) => {
  const qrTimeoutMessage = (
    <FormsRow>
      <div className={styles.SbpQr__qrTimeoutMessage}>
        <span>Время на проведение платежа ограничено. Когда таймер истечет, нужно будет начать платеж заново&nbsp;&nbsp;</span>
        <Timer
          initialCounter={QrTimeout}
          onTimeEnd={onClose}
        />
        <span>&nbsp;сек.</span>
      </div>
    </FormsRow>
  );

  return (
    <div className={styles.SbpQr__qr}>
      <QRCodeSVG
        value={sbpQrLink}
        size={128}
        bgColor='#ffffff'
        fgColor='#1d1346'
        level='H'
        includeMargin={false}
        // imageSettings={{
        //   src: '../../../../../../images/sbp_logo_rgb-02.png',
        //   x: undefined,
        //   y: undefined,
        //   height: 24,
        //   width: 24,
        //   excavate: true
        // }}
      />

      {amount > 0 && (
        <div className={styles.SbpQr__price}>
          <div>к оплате:</div>
          <div className={styles.SbpQr__cost}>
            {numberToMoneyWithoutCurrency(amount)}
          </div>
          <div className={styles.SbpQr__currency}>{currency}</div>
        </div>
      )}
      {QrTimeout > 0 && (qrTimeoutMessage)}
    </div>
  );
};


SbpQr.propTypes = {
  sbpQrLink: PropTypes.string.isRequired,
  QrTimeout: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired
};


export default SbpQr;
