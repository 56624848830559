import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import useAuth from '../../../../hooks/use-auth';
import RecallForm from '../ContactVariants/RecallForm';
import FormsRow from '../FormsRow';
import Popup from '../Popup';
import usePopup from '../../../../hooks/use-popup';
import { OrderContext } from '../../context/OrderContext';
import Action from '../Action';

import * as styles from './Hint.module.scss';

const Hint = ({ content, onClose }) => {
  const { order } = useContext(OrderContext);
  const [isRecallFormOpen, openRecallForm, closeRecallForm] = usePopup();
  const { user } = useAuth();

  const handleCloseRecallForm = () => {
    closeRecallForm();
    onClose();
  };

  return (
    <>
      <FormsRow>
        <div className={styles.Hint__content} dangerouslySetInnerHTML={{ __html: content }} />
      </FormsRow>
      <Action
        to='/'
        onClick={onClose}
        themes={['Button', 'Fullwidth', 'ButtonCommon', 'ButtonWarning']}
        className={styles.Hint__button}
      >
        <span>Ок, понятно</span>
      </Action>
      <Action
        to='/'
        onClick={openRecallForm}
        themes={['Button', 'ButtonSecondary', 'Fullwidth', 'ButtonCommon']}
        className={styles.Hint__button}
      >
        <span>Cвяжитесь со мной</span>
      </Action>

      <Popup
        show={isRecallFormOpen}
        onClose={handleCloseRecallForm}
        escapeClose={false}
        outsideClose={false}
        variant='small'
      >
        <RecallForm
          onClose={handleCloseRecallForm}
          recallPhone={user.phone}
          recallName={user.name}
          recallDescription={`spotway.ru/${order.pnr}/edit`}
        />
      </Popup>
    </>
  );
};

Hint.propTypes = {
  content: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

Hint.defaultProps = {
  content: null
};

export default Hint;
