import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import FormsRow from '../../../../FormsRow';
import Select from '../../../../Select';
import PageSectionHeader from '../../../../PageSectionHeader';

import * as styles from './AnnuledTemplateForm.module.scss';
import Action from '../../../../Action';
import { OrderContext } from '../../../../../context/OrderContext';

const FormSchema = Yup.object().shape({
  dropDownField: Yup.string()
    .trim()
    .required('Заполните причину аннуляции')
});

const AnnuledTemplateForm = ({ onClose }) => {
  const {
    order,
    handleOrderSelfCancel
  } = useContext(OrderContext);
  const {
    register,
    watch,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'onChanges',
    reValidateMode: 'onChange',
    resolver: yupResolver(FormSchema)
  });

  const dropDownField = watch('dropDownField');

  const DropDownField = () => {
    const options = order.annuled_templates.map(option => (
      <option value={option.id}>{option.name}</option>
    ));

    return (
      <FormsRow>
        <Select
          labelText='Причина аннуляции?'
          name='dropDownField'
          {...register('dropDownField', { required: true })}
          error={errors.dropDownField?.message}
          onClick={() => trigger('dropDownField')}
          // value={dropDownField}
        >
          <option value=''>Выбрать</option>
          {options}
        </Select>
      </FormsRow>
    );
  };

  const onSubmit = (values) => {
    onClose();
    // console.log(values);
    // console.log(dropDownField);

    const params = {
      order: {
        status_cd: 3
      },
      comment: order.annuled_templates.filter(f => f.id === parseInt(dropDownField, 10))[0]?.name
    };
    // console.log(params);

    handleOrderSelfCancel(params, (orderResult) => {
      // console.log(orderResult);
    });
  };

  useEffect(() => {
    trigger('dropDownField');
  }, [trigger]);

  return (
    <form
      className={styles.AnnuledTemplateForm}
      onSubmit={handleSubmit(data => onSubmit(data))}
      id='clarifications_id'
    >
      <div className={styles.AnnuledTemplateForm__annuledForm}>
        <PageSectionHeader>Отменить заказ</PageSectionHeader>
      </div>
      <FormsRow>
        <div className={styles.AnnuledTemplateForm__annuledContent} dangerouslySetInnerHTML={{ __html: order.description }} />
      </FormsRow>
      <FormsRow>
        {DropDownField()}
      </FormsRow>
      <FormsRow>
        <Action
          themes={dropDownField > 0 ? ['Button', 'Fullwidth'] : ['Button', 'Fullwidth', 'ButtonSecondary']}
        >
          Да, подтверждаю
        </Action>
      </FormsRow>
    </form>
  );
};

AnnuledTemplateForm.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  onClose: PropTypes.func.isRequired
};

export default AnnuledTemplateForm;
