import React from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';

import * as styles from './PasswordForm.module.scss';

import FormsRow from '../../FormsRow/FormsRow';
import DigitPasswordTextInput from '../../DigitPasswordTextInput';

const FormSchema = Yup.object().shape({
  password: Yup.string()
    .length(4, 'Пароль должен состоять из 4х символов.')
    .required('Введите пароль'),
  password_confirmation: Yup.string()
    .length(4, 'Пароль должен состоять из 4х символов.')
    .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
    .required('Введите пароль')
});

const PasswordForm = ({ formId, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(FormSchema)
  });

  return (
    <form className={styles.PasswordForm} onSubmit={handleSubmit(data => onSubmit(data, formId))} id={formId}>
      <FormsRow>
        <DigitPasswordTextInput
          labelText='Придумайте новый пароль'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('password')}
          error={errors.password?.message}
        />
      </FormsRow>

      <FormsRow>
        <DigitPasswordTextInput
          labelText='Повторите новый пароль'
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('password_confirmation')}
          error={errors.password_confirmation?.message}
        />
      </FormsRow>
    </form>
  );
};

PasswordForm.propTypes = {
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default PasswordForm;
