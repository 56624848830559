import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FreeMode, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';
import OfferCard, { OfferCardData } from '../OfferCard';

import ChevronLeftIcon from '../../../../../icons/chevron-left.svg';
import ChevronRightIcon from '../../../../../icons/chevron-right.svg';

import * as styles from './OffersSlider.module.scss';

const OffersSlider = ({
  offers,
  sliderClassName,
  compactVariant
}) => {
  if (offers === undefined || offers.length === 0) return '';

  // TODO: move from state to ref
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);
  const [paginationEl, setPaginationEl] = useState(null);

  return (
    <>
      <Swiper
        className={sliderClassName}
        modules={[FreeMode, Pagination, Navigation]}
        slidesPerView='auto'
        navigation={{
          prevEl,
          nextEl,
          disabledClass: styles.OffersSlider__navDisabled
        }}
        pagination={{
          el: paginationEl,
          clickable: true
        }}
      >
        {offers.map(offer => (
          <SwiperSlide className={styles.OffersSlider__offer} key={offer.slug}>
            <OfferCard offer={offer} compactVariant={compactVariant} />
          </SwiperSlide>
        ))}
        {offers?.length > 3 && (
          <>
            <div
              className={styles.OffersSlider__navLeft}
              ref={node => setPrevEl(node)}
            >
              <IconSvg icon={ChevronLeftIcon} className={styles.OffersSlider__chevron} />
            </div>
            <div
              className={styles.OffersSlider__navRight}
              ref={node => setNextEl(node)}
            >
              <IconSvg icon={ChevronRightIcon} className={styles.OffersSlider__chevron} />
            </div>
          </>
        )}
      </Swiper>
      <div
        className={styles.OffersSlider__navPagination}
        ref={node => setPaginationEl(node)}
      />
    </>
  );
};

OffersSlider.propTypes = {
  offers: PropTypes.arrayOf(OfferCardData).isRequired,
  sliderClassName: PropTypes.string.isRequired,
  compactVariant: PropTypes.bool
};

OffersSlider.defaultProps = {
  compactVariant: false
};

export default OffersSlider;
