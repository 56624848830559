import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';

import * as styles from './PaymentChannel.module.scss';

import MirIcon from '../../../../../icons/mir-lg.svg';
import VisaIcon from '../../../../../icons/visa-lg.svg';
import sberpayIcon from '../../../../../icons/sber.svg';
import sbpIcon from '../../../../../icons/sbp.svg';

const PaymentChannel = ({ channel, className, onClick }) => {
  const Icon = {
    mir: MirIcon,
    sberpay: sberpayIcon,
    visa: VisaIcon,
    sbp: sbpIcon
  };

  const styleIcon = {
    mir: styles.PaymentChannel__mir,
    mastercard: styles.PaymentChannel__mastercard,
    visa: styles.PaymentChannel__visa,
    sbp: styles.PaymentChannel__sbp
  };

  return (
    <div
      className={cn(styles.PaymentChannel, { [className]: className })}
      onClick={onClick}
    >
      <IconSvg
        icon={Icon[channel]}
        className={cn(styles.PaymentChannel__defaultSize, styleIcon[channel])}
      />
    </div>
  );
};

PaymentChannel.propTypes = {
  channel: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
};

PaymentChannel.defaultProps = {
  className: '',
  onClick: null
};

export default PaymentChannel;
