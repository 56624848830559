/* eslint-disable no-undef */
import React, { useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { isBrowser } from '../../../../helpers/browser';

const PORTAL_ELEMENT_ID = 'portal';

const Portal = ({ children }) => {
  if (!isBrowser) return null;

  const target = document.getElementById(PORTAL_ELEMENT_ID);
  const el = React.useMemo(() => document.createElement('div'), []);

  useLayoutEffect(() => {
    if (target) target.appendChild(el);
    return () => {
      if (target) target.removeChild(el);
    };
  }, [el, target]);

  return createPortal(children, el);
};

export default React.memo(Portal, () => false);
