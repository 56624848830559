import React, { useContext } from 'react';

import { OrderContext } from '../../../context/OrderContext';
import { ORDER_ITEMS } from '../../MenuItems';
import Documents from './Documents';
import Members from './Members';
import Service from './Service';
import ServiceSelect from './ServiceSelect';
import Loader from '../../Loader';

const Section = () => {
  const { itemMenu, isLoading } = useContext(OrderContext);

  return (
    <>
      <Loader view={isLoading} />
      {itemMenu === ORDER_ITEMS.documents && <Documents />}
      {itemMenu === ORDER_ITEMS.members && <Members />}
      {itemMenu === ORDER_ITEMS.orderItems && <Service />}
      {itemMenu === ORDER_ITEMS.subOrders && <ServiceSelect />}
    </>
  );
};

export default Section;
