export const isBrowser = typeof window !== 'undefined'
  && typeof window.document !== 'undefined';

export const isMobileDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileUserAgent = /mobile|iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);
  const isMobileWidth = window.innerWidth < 768;
  const isMobileTouch = ('ontouchstart' in document.documentElement && userAgent.match(/mobi/));

  if (isMobileUserAgent) return true;
  if (isMobileWidth) return true;
  if (isMobileTouch) return true;

  return false;
};

export const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name, value, options = {}) => {
  options = {
    path: '/',
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

export const deleteCookie = (name) => {
  setCookie(name, '', {
    'max-age': -1
  });
};
