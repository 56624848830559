import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../TextInput';
import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';

import CalendarIcon from '../../../../../icons/calendar-new.svg';

import * as styles from './DateTextInput.module.scss';

const DateTextInput = forwardRef((props, ref) => {
  const {
    placeholder,
    type,
    ...otherInputProps
  } = props;

  const mask = {
    date: true,
    delimiter: '.',
    datePattern: ['d', 'm', 'Y']
  };

  return (
    <TextInput
      placeholder={placeholder}
      type={type}
      mask={mask}
      {...otherInputProps}
      afterContent={
        <div className={styles.DateTextInput__icon}><IconSvg icon={CalendarIcon} /></div>
      }
      ref={ref}
    />
  );
});

DateTextInput.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string
};

DateTextInput.defaultProps = {
  placeholder: 'ДД.ММ.ГГГГ',
  type: 'text'
};

export default DateTextInput;
