import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import * as styles from './FormPanel.module.scss';

const FormPanel = ({ children, className }) => (
  <div
    className={
      cn(
        styles.FormPanel,
        { [className]: className }
      )
    }
  >
    {children}
  </div>
);

FormPanel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

FormPanel.defaultProps = {
  className: ''
};

export default FormPanel;
