import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import * as styles from './Loader.module.scss';

const Loader = ({ view, winter, summer, text }) => {
  return (
    <>
      {view && (
        <div
          className={cn(styles.Loader__modal, styles.Loader__modal_display, {
            [styles.Loader__winter]: winter,
            [styles.Loader__summer]: summer
          })}
        >
          <div className={styles.Loader__modal_content}>
            {text}
          </div>
        </div>
      )}
    </>
  );
};

Loader.propTypes = {
  view: PropTypes.bool.isRequired,
  winter: PropTypes.bool,
  summer: PropTypes.bool,
  text: PropTypes.string
};

Loader.defaultProps = {
  winter: true,
  summer: false,
  text: ''
};

export default Loader;
