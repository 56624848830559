import React, { useContext, useEffect } from 'react';
// import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { OrderContext } from '../../../context/OrderContext';
import Select from '../../Select';
import FormsRow from '../../FormsRow';
import TextInput from '../../TextInput';
import useDebounce from '../../../../../hooks/use-debounce';

import * as styles from './AccommodateWithWish.module.scss';

const errorText = 'ФИО друга или номер заказа';

const AccommodateWithWish = () => {
  const { order, accommodateWithWish, handleUpdateOrder } = useContext(OrderContext);
  const submitRef = React.createRef();

  const {
    register,
    watch,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChanges',
    reValidateMode: 'onChange',
    defaultValues: {
      accommodateType: order.accommodate_with_wish_type || 'null',
      accommodate: order.accommodate_with_wish
    }
  });

  const accommodateType = watch('accommodateType');
  const accommodate = watch('accommodate');

  const deferredAccommodateType = useDebounce(accommodateType, 1000);
  const deferredAccommodate = useDebounce(accommodate, 1000);

  const options = (
    Object.entries(accommodateWithWish).map(([k, v]) => (
      <option key={`option-${k}`} value={k}>{v}</option>
    ))
  );

  useEffect(() => {
    if ((order.accommodate_with_wish_type || 'null') === accommodateType
      && order.accommodate_with_wish === accommodate) return;

    if (deferredAccommodateType !== 'null' && deferredAccommodate) {
      submitRef.current.click();
    }
    if (deferredAccommodateType === 'null' && !deferredAccommodate) {
      submitRef.current.click();
    }
  }, [deferredAccommodateType, deferredAccommodate]);

  const handleAccommodateType = (e) => {
    const { value } = e.currentTarget;

    setValue('accommodateType', value);

    if (value === 'null') {
      setValue('accommodate', '');
    }

    if (value !== 'null' && !accommodate) {
      setError('accommodate', { message: errorText });
    }
  };

  const handleAccommodate = (e) => {
    const { value } = e.currentTarget;

    if (value) {
      setValue('accommodate', value);
    } else {
      setValue('accommodate', '');
      setError('accommodate', { message: errorText });
    }
  };

  const onSubmit = (formData) => {
    console.log('onSubmit => ', formData);
    // alert(`Sending: ${JSON.stringify(formData)}`);

    const params = {
      order: {
        accommodate_with_wish_type: formData.accommodateType,
        accommodate_with_wish: formData.accommodate
      }
    };

    handleUpdateOrder(params, (orderResult) => {
      console.log(orderResult);
    });
  };

  return (
    <form
      className={styles.AccommodateWithWish}
      onSubmit={handleSubmit(data => onSubmit(data))}
    >
      <div>
        <FormsRow>
          <Select
            className={styles.AccommodateWithWish__select}
            name='accommodate_with_wish_type'
            {...register('accommodateType')}
            error={errors.accommodateType?.message}
            value={accommodateType}
            onChange={handleAccommodateType}
          >
            {options}
          </Select>
        </FormsRow>

        <FormsRow>
          {accommodateType !== 'null' && (
            <TextInput
              className={styles.AccommodateWithWish__select}
              name='accommodate_with_wish'
              placholder={errorText}
              {...register('accommodate')}
              error={errors.accommodate?.message}
              value={accommodate}
              onChange={handleAccommodate}
            />
          )}
        </FormsRow>
        <input type='submit' ref={submitRef} style={{ display: 'none' }} />
      </div>
    </form>
  );
};

AccommodateWithWish.propTypes = {

};

export default AccommodateWithWish;
