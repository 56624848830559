import React from 'react';
import PropTypes from 'prop-types';

import Anchor from '../../Anchor/Anchor';
import BaseShellContainer from '../../BaseShellContainer';
import Feature from '../../Feature';
import PageSectionHeader from '../../PageSectionHeader/PageSectionHeader';

import * as styles from './FeaturesSection.module.scss';

const FeaturesSection = ({ features }) => {
  if (features.length === 0) return '';

  return (
    <section className={styles.FeaturesSection}>
      <Anchor className='anchor about' name='about' />
      <BaseShellContainer>
        <PageSectionHeader>Наши преимущества</PageSectionHeader>
        <div className={styles.FeaturesSection__features}>
          {features.map(feature => (
            <div
              className={styles.FeaturesSection__featureItem}
              key={feature.id}
            >
              <Feature feature={feature} />
            </div>
          ))}
        </div>
      </BaseShellContainer>
    </section>
  );
};

FeaturesSection.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string
  })).isRequired
};

export default FeaturesSection;
